import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';

import { useDataContext } from 'context/UserContext';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { IoPeople } from 'react-icons/io5';
import { MdErrorOutline } from 'react-icons/md';
import { BsCurrencyDollar } from 'react-icons/bs';
import { MdOutlinePayment } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';

import {
  createPromocode,
  getAffiliate,
  getPromocode,
  hasAffiliate,
  updatePaypal
} from 'utils/referrals';

const validateEmail = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

function AffiliateProgram() {
  const { user } = useDataContext();

  let language = 'en';

  if (!user.language) {
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  const [promocode, setPromocode] = useState('');
  const [hasPromocode, setHasPromocode] = useState(false);

  const [email, setEmail] = useState('');
  const [affiliate, setAffiliate] = useState(null);
  const [makeAffiliate, setMakeAffiliate] = useState(false);

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    hasAffiliate(data => {
      if (data.hasAffiliate) {
        getAffiliate(affiliateData => {
          setAffiliate(affiliateData);
        }, null);
      } else {
        setMakeAffiliate(true);
      }
    });
  }, []);

  const handlePromocodeChange = event => {
    setPromocode(event.target.value.toUpperCase());
  };

  useEffect(() => {
    getPromocode(data => {
      if (data.hasPromocode) {
        setHasPromocode(data.hasPromocode);
        setPromocode(data.promocode);
      }
    });
  }, []);

  const submitCustomPromocode = () => {
    createPromocode(t, promocode).then(res => {
      console.log(res);
    });
  };

  return (
    <>
      {!affiliate && makeAffiliate && (
        <div className="overflow-hidden rounded-md">
          <div className="gradient z-[600] h-1 w-full rounded-t-lg" />
          <div className="flex mb-5 bg-gradient p-2 lg:p-5 shadow-md">
            <div className="w-full flex items-start justify-center">
              <div className="p-3 lg:p-7 flex flex-col gap-5 text-white">
                <div className="flex flex-col gap-1 lg:gap-2 mt-3 lg:mt-10">
                  <h3 className="text-3xl lg:text-5xl font-medium lg:leading-[50px] leading-[40px]">
                    {t('settings.referralProgramUi.getPaidCash10')}
                  </h3>
                  <h3 className="text-3xl lg:text-5xl font-medium lg:leading-[50px] leading-[40px]">
                    {t('settings.referralProgramUi.perPersonYourRefer')}
                  </h3>
                </div>
                <p className="text-sm lg:text-base font-light leading-6">
                  {t('settings.referralProgramUi.description')}
                </p>
                <div className="my-5 lg:my-8">
                  <label className="w-full flex items-center rounded-full px-4 lg:px-5 py-1 lg:py-3 bg-gray-50 text-gray-700 border-none shadow-xl  focus:border-highlight focus:outline-none focus:ring-highlight">
                    <input
                      id="affiliate-paypal"
                      type="email"
                      className="appearance-none flex-1 placeholder-gray-400 bg-gray-50 transition w-full bg-none border-none"
                      placeholder={t('settings.paypalEmail')}
                      value={email}
                      onChange={handleEmailChange}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (validateEmail(email)) {
                          updatePaypal(email);
                        } else {
                          toast.error(
                            t('settings.referralProgramUi.validEmail')
                          );
                        }
                      }}
                      className="flex items-center text-gradient group"
                    >
                      <p className="text-sm font-semibold">
                        {t('settings.enroll')}
                      </p>
                      <MdArrowForwardIos className="text-xl text-purple-500 group-hover:translate-x-2 duration-500" />
                    </button>
                  </label>
                  {email.length > 2 ? (
                    <div>
                      {!validateEmail(email) && (
                        <div
                          class="text-white flex items-center px-4 text-xs mt-3"
                          role="alert"
                        >
                          <div>
                            <MdErrorOutline className="inline text-xl mr-1 text-white" />
                          </div>
                          {t('settings.referralProgramUi.validEmail')}
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="w-full hidden lg:block">
              <div className="ml-10">
                <img
                  src="/referral-program.png"
                  alt="Affiliate Program"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {affiliate && (
        <div className="overflow-hidden rounded-md">
          <div className="gradient z-[600] h-1 w-full rounded-t-lg" />
          <div className="mb-10 bg-white p-5 shadow-md">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="rounded-md p-4 bg-gray-50 col-span-1 hidden lg:block">
                <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-100 mb-3">
                  <IoPeople className="text-2xl text-gray-700" />
                </div>
                <div className="text-base font-medium">
                  {t('settings.visitorCount')}
                </div>
                <div className="my-2 text-3xl font-medium text-gray-500">
                  {affiliate.visitors}
                </div>
                <p className="text-xs text-gray-500 leading-5">
                  {t('settings.peopleVisited')}
                </p>
              </div>
              <div className="rounded-md p-4 bg-gray-50 col-span-1 hidden lg:block">
                <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-100 mb-3">
                  <MdOutlinePayment className="text-2xl text-gray-700" />
                </div>
                <div className="text-base font-medium">
                  {t('settings.purchases')}
                </div>
                <div className="my-2 text-3xl font-medium text-gray-500">
                  {affiliate.conversions}
                </div>
                <p className="text-xs text-gray-500 leading-5">
                  {t('settings.peoplePaid')}
                </p>
              </div>
              <div className="rounded-md p-4 bg-gray-50 col-span-1">
                <div className="rounded-full w-10 h-10 flex items-center justify-center bg-gray-100 mb-3">
                  <BsCurrencyDollar className="text-2xl text-gray-700" />
                </div>
                <div className="text-base font-medium">
                  {t('settings.totalEarnings')}
                </div>
                <div className="my-2 text-3xl font-medium text-gray-500">
                  ${affiliate.conversions * 10}
                </div>
                <p className="text-xs text-gray-500 leading-5">
                  {t('settings.totalEarningsInfo')}
                </p>
              </div>
            </div>

            <div className="mt-6 flex flex-col lg:flex-row items-center gap-5 justify-between">
              <div className="w-full border rounded-md p-5">
                <div className="text-lg font-medium">
                  {t('settings.customPromocode')}
                </div>
                <p className="text-sm">{t('settings.customDesc')}</p>
                <div className="flex mt-6 items-center">
                  <input
                    id="custom-promocode"
                    type="text"
                    className="mr-2 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    placeholder={promocode || t('settings.enterPromo')}
                    // value={promocode}
                    disabled={hasPromocode}
                    onChange={handlePromocodeChange}
                  />
                  <button
                    className={
                      hasPromocode
                        ? 'ml-auto items-center justify-end gap-1.5 rounded-md bg-gray-200 px-4 py-3 font-medium leading-none tracking-wide transition-all lg:inline-flex lg:text-sm'
                        : 'button-gradient rounded-r-md px-4 py-3 font-medium text-white'
                    }
                    disabled={hasPromocode}
                    onClick={submitCustomPromocode}
                  >
                    {t('settings.create')}
                  </button>
                </div>
              </div>
              <div className="w-full border rounded-md p-5">
                <div className="text-lg font-medium">
                  {t('settings.paypalEmail')}
                </div>
                <p className="text-sm">{t('settings.paypalInfo')}</p>
                <input
                  id="affiliate-paypal"
                  type="email"
                  className="mt-6 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                  placeholder={affiliate.paypal_email || 'PayPal Email'}
                  onChange={handleEmailChange}
                />
                {email.length > 2 ? (
                  <span className="text-xs font-normal text-rose-500">
                    {!validateEmail(email) &&
                      'Please enter a valid email address.'}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="border rounded-md p-5 my-6">
              <div className="text-lg font-medium">
                {t('settings.referralProgram')}
              </div>
              <p className="text-sm">{t('settings.shareLink')}</p>
              <div className="mt-2 flex items-center gap-2.5">
                <label className="w-full flex items-center rounded-md overflow-hidden border border-gray-300 ">
                  <input
                    id="affiliate-link"
                    type="text"
                    className="w-full flex-1 border-none"
                    value={`https://freebieflow.com/?${
                      affiliate.links[0].url.split('?')[1]
                    }`}
                    readOnly
                  />
                  <button
                    className="button-gradient !rounded-l-none  font-medium text-white"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://freebieflow.com/?${
                          affiliate.links[0].url.split('?')[1]
                        }`
                      );
                      toast.success(t('settings.copyPopup'));
                    }}
                    type="button"
                  >
                    {t('settings.copy')}
                  </button>
                </label>
              </div>
            </div>
            <div>
              <button
                className="button-gradient mt-6 rounded-md px-4 py-3 font-medium text-white"
                onClick={() => {
                  if (validateEmail(email)) {
                    updatePaypal(email);
                  } else {
                    toast.error('Please enter a valid email address.');
                  }
                }}
                type="button"
              >
                {t('settings.update')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AffiliateProgram;
