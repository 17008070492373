import axios from 'axios';
import toast from 'react-hot-toast';

/**
 * Get affiliate data for the user
 * @param paypalEmail The user's paypal email. If the user doesn't have an affiliate yet, this will be used to create one.
 *                    If the user already has an affiliate, this will be ignored and is optional.
 *                    If the user doesn't have an affiliate and this is not provided, an error will be returned.
 */
export const getAffiliate = (onUpdate, paypalEmail = null) => {
  return axios('/get_affiliate', {
    method: 'POST',
    data: {
      paypalEmail
    }
  })
    .then(res => {
      if (!res.data) {
        console.log('No affiliate found.');
      } else {
        console.log(res.data);
        onUpdate(res.data);
      }
    })
    .catch(e => {
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong while getting affiliate!');
    });
};

/**
 * Check if the user has an existing affiliate.
 * @param onUpdate The function to call when the request is complete.
 */
export const hasAffiliate = onUpdate => {
  return axios('/has_affiliate', {
    method: 'GET'
  })
    .then(res => {
      onUpdate(res.data);
    })
    .catch(e => {
      console.log(e);
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong while checking affiliate!');
    });
};

/**
 * Update the paypal email for the user's affiliate.
 * @param paypalEmail The paypal email to update the affiliate with.
 */
export const updatePaypal = paypalEmail => {
  return axios('/update_paypal', {
    method: 'PUT',
    data: {
      paypalEmail
    }
  })
    .then(() => {
      toast.success('Successfully enrolled in the referral program!');
      window.location.reload(true);
    })
    .catch(e => {
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong while updating PayPal!');
    });
};

/**
 * Create a custom promocode for user via stripe.
 * @param promocode The promocode to create.
 */
export const createPromocode = (t, promocode, onUpdate) => {
  return axios('/create_promocode', {
    method: 'POST',
    data: {
      promocode
    }
  })
    .then(res => {
      if (onUpdate) {
        onUpdate(res.data);
      } else {
        if (res.data.error) {
          toast.error(res.data.error);
        } else {
          toast.success(t('settings.successPromo'));
        }
      }
    })
    .catch(e => {
      const error = e?.response?.data?.error;
      toast.error(error ?? t('settings.somethingWentWrong'));
    });
};

export const getPromocode = onUpdate => {
  return axios('/promocode', {
    method: 'GET'
  })
    .then(res => {
      onUpdate(res.data);
    })
    .catch(e => {
      console.log(e);
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong!');
    });
};
