import React from 'react';
import { LuBadgeCheck, LuInfo, LuAlertCircle } from 'react-icons/lu';

export default function Callout(props) {
  const { text, variant } = props;

  const variants = {
    info: {
      icon: <LuInfo size={20} className="w-6" />,
      class: 'text-gray-500'
    },
    warning: {
      icon: <LuAlertCircle size={20} className="w-6" />,
      class: 'text-highlight'
    }
    // danger: {
    //   icon: <LuAlertCircle size={20} />,
    //   class: 'bg-red-100 text-red-500 border-red-200'
    // },
    // success: {
    //   icon: <LuBadgeCheck size={20} />,
    //   class: 'bg-green-100 text-green-500 border-green-200'
    // }
  };

  return (
    <div
      className={`flex gap-3 text-sm sm:items-center py-2 rounded-lg w-fit ${variants[variant].class}`}
    >
      {variants[variant].icon} {text}
    </div>
  );
}
