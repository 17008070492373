import React, { useEffect, useState } from 'react';

import useAxios from 'axios-hooks';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { formatPrice } from 'utils/analytics';
import { useDataContext } from 'context/UserContext';
import { getAffiliate, hasAffiliate, getPromocode } from 'utils/referrals';

import Callout from 'global-components/Callout';

function TopCards() {
  const { user } = useDataContext();
  const { t, i18n } = useTranslation();
  const [affiliate, setAffiliate] = useState(null);
  const [promoCode, setPromocode] = useState(null);

  useEffect(() => {
    getPromocode(data => {
      if (data.hasPromocode) {
        setPromocode(data.promocode);
      }
    });
  }, []);

  useEffect(() => {
    hasAffiliate(data => {
      if (data.hasAffiliate) {
        getAffiliate(affiliateData => {
          setAffiliate(affiliateData);
        }, null);
      }
    });
  }, []);

  useEffect(() => {
    console.log('CHANGING LANGUAGE TO user.language', user.language);
    i18n.changeLanguage(user.language || 'es').catch(error => {
      console.error('Error changing language:', error);
    });
  }, [i18n, user.language]);

  const [{ data: checkoutList = [] }] = useAxios(
    '/analytics/checkouts?filterCancelled=true'
  );

  const checkoutTotal = checkoutList?.reduce((acc, cur) => {
    return acc + cur.price;
  }, 0);

  const checkoutTotalThisMonth = checkoutList?.reduce((acc, cur) => {
    if (new Date(cur.createdAt).getMonth() === new Date().getMonth()) {
      return acc + cur.price;
    }
    return acc;
  }, 0);

  console.log('checkoutTotalThisMonth', checkoutTotalThisMonth);

  if (checkoutTotalThisMonth > 0) {
    const subscriptionMultiple = Math.ceil(
      checkoutTotalThisMonth / user.subscription.price
    );

    return (
      <div className="rounded-md border bg-white p-5 shadow-sm flex flex-col lg:flex-row lg:items-center gap-2">
        <div className="flex-1">
          <div className="mb-2.5 flex items-center gap-1.5 font-bold">
            <span>{t('dashboard.niceJob')}</span>
          </div>
          <div className="flex flex-col">
            <p className="opacity-95">
              {t('dashboard.savedTotal.0')}{' '}
              <strong>${formatPrice(checkoutTotal)} </strong>
              {t('dashboard.savedTotal.1')}{' '}
              <strong>
                {checkoutList?.length} package
                {checkoutList?.length === 1 ? '' : 's'}
              </strong>
              !
            </p>
            {checkoutTotalThisMonth >= user.subscription.price && (
              <p className="opacity-95">
                {t('dashboard.savedTotal.2.0')}{' '}
                <strong>
                  {subscriptionMultiple > 1
                    ? `${subscriptionMultiple} ${t('dashboard.savedTotal.2.1')}`
                    : '!'}
                </strong>
              </p>
            )}
          </div>
          <div className="mt-4">
            <Callout text={t('dashboard.canceledDisclaimer')} variant="info" />
          </div>
        </div>

        {affiliate && (
          <div className="flex flex-col gap-2">
            {promoCode && (
              <div className="bg-gray-100 rounded-full py-2 px-6 text-center">
                {t('settings.yourCouponCode')}: <strong>{promoCode}</strong>
              </div>
            )}

            {affiliate.links.length > 0 && (
              <button
                className="bg-gradient py-2 px-8 rounded-2xl font-medium text-center gap-2"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://freebieflow.com/?${
                      affiliate.links[0].url.split('?')[1]
                    }`
                  );
                  toast.success(t('settings.copyPopup'));
                }}
                type="button"
              >
                <span className="text-white font-medium">
                  {t('settings.copyYourReferral')}
                </span>
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
  return null;
}

export default TopCards;
