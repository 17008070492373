import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const ConfirmAlert = ({
  title,
  message,
  onConfirm,
  onCancel,
  user,
  confirmButtonText
}) => {
  let language = 'en';

  if (!user.language) {
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  return (
    <div className="p-3">
      <div className="max-w-[450px] p-6 lg:p-7 m-2 mx-auto bg-white border rounded-md shadow-sm">
        <h1 className="text-lg lg:text-xl font-bold text-gray-900 mb-2 leading-6 lg:leading-7">
          {title}
        </h1>
        {message && (
          <p className="text-[13px] lg:text-sm leading-5 lg:leading-6 text-gray-500">
            {message}
          </p>
        )}
        <div className="mt-4 lg:mt-7 flex flex-col-reverse gap-3 lg:flex-row items-center justify-start lg:gap-1">
          <button
            className="bg-gray-100 hover:bg-gray-200 duration-300 w-full lg:w-auto flex items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-gray-500 hover:text-gray-600 transition-all lg:text-sm"
            onClick={onCancel}
          >
            {t('confirmationModal.cancel')}
          </button>
          <button
            className="button-gradient w-full whitespace-nowrap button-gradient flex items-center duration-300 justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm lg:ml-4"
            onClick={onConfirm}
          >
            {confirmButtonText || t('confirmationModal.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAlert;
