import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useDataContext } from 'context/UserContext';

const AmazonPrimeWarningModal = ({
  show,
  onClose,
  email
}) => {
  const { showNewMessage } = useIntercom();

  let language = 'en';

  const { user } = useDataContext();

  if (!user.language) {
    console.log('NO LANGUAGE - SETTING');
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  console.log('USING LANGUAGE: ');
  console.log(user.language || 'en');

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  console.log(t);

  const connectToSupport = e => {
    e.preventDefault();
    showNewMessage(
      `Hey! Could you please help me to solve Amazon Prime warning for my account. My account is ${email}.`
    );
    onClose()
  };

  return (
    <Transition.Root show={Boolean(show)} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>
        <form
          onSubmit={connectToSupport}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative max-w-xl p-5 lg:p-9 overflow-hidden text-left transition-all transform bg-white rounded-md shadow-xl">
                <div class="w-full absolute left-0 right-0 top-0 h-1">
                  <div class="h-full w-full  overflow-hidden">
                    <div class=" w-full h-full gradient left-right"></div>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={onClose}
                  className="absolute top-[15px] right-5 opacity-25 hover:opacity-30 hover:bg-gray-200 duration-150 p-2 rounded-full"
                >
                  <FaTimes />
                </button>

                <div className="text-gray-600 mt-5 lg:mt-2">
                  <div className="flex flex-col items-start  gap-1">
                    <h3 className="text-xl lg:text-2xl font-normal ">
                      {t(`dashboard.amazonPrimeWarning.modal.title`)}
                    </h3>
                    <p className="font-light text-start text-sm lg:text-base">
                      {email}
                    </p>
                  </div>
                  <div className="mt-5 relative rounded-lg overflow-hidden">
                    <p className="font-normal text-sm lg:text-base text-gray-600">
                      {t('dashboard.amazonPrimeWarning.modal.description')}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col-reverse gap-3 lg:flex-row justify-between mt-5 sm:mt-6">
                  <button
                    type="button"
                    onClick={onClose}
                    className="flex duration-300 w-full lg:w-auto items-center justify-center gap-1.5 whitespace-nowrap rounded-md bg-gray-100 px-4 py-3 text-sm font-medium leading-none tracking-wide transition-all hover:bg-gray-200 active:bg-gray-300 lg:inline-flex mr-4"
                  >
                    {t(`dashboard.amazonPrimeWarning.modal.buttons.ok`)}
                  </button>
                  <button
                    type="submit"
                    className="button-gradient text-center button-gradient flex w-full items-center justify-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none text-button-text transition-all lg:text-sm"
                  >
                    {t(`dashboard.amazonPrimeWarning.modal.buttons.contactSupport`)}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};

export default AmazonPrimeWarningModal;
