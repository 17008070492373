import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MdArrowForwardIos } from 'react-icons/md';
import React, { useEffect, useState } from 'react';
import { getAffiliate, hasAffiliate } from 'utils/referrals';

import { useDataContext } from 'context/UserContext';

const ReferAFriend = () => {
  const { user } = useDataContext();

  const [affiliate, setAffiliate] = useState(null);
  const [makeAffiliate, setMakeAffiliate] = useState(false);

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  useEffect(() => {
    hasAffiliate(data => {
      if (data.hasAffiliate) {
        getAffiliate(affiliateData => {
          setAffiliate(affiliateData);
        }, null);
      } else {
        setMakeAffiliate(true);
      }
    });
  }, []);

  return (
    <div>
      {!affiliate && makeAffiliate && (
        <div className="overflow-hidden rounded-md my-2">
          <div className="flex bg-gradient p-1 shadow-md rounded-md">
            <div className="w-full flex items-start justify-center">
              <div className="p-3 lg:p-4 flex items-center gap-5 text-white">
                <div className="flex flex-col lg:gap-2">
                  <h3 className="text-lg lg:text-3xl font-medium ">
                    {t('settings.referralProgramUi.getPaidCash10')}{' '}
                    {t('settings.referralProgramUi.perPersonYourRefer')}
                  </h3>
                </div>
                <div className="flex items-center justify-end">
                  <div className="bg-gray-50 shadow-lg rounded-2xl px-2 py-1 lg:px-4 lg:py-2 group cursor-pointer">
                    <Link
                      to="/dashboard/settings"
                      className="flex gap-1 items-center text-gradient"
                    >
                      <p className="text-sm lg:text-base font-medium">
                        {t('settings.enroll')}
                      </p>
                      <MdArrowForwardIos className="text-lg lg:text-xl text-purple-500 group-hover:translate-x-2 duration-500" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferAFriend;
