import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import // Import css
import ConfirmAlert from '../dashboard/components/ConfirmAlert';
import { useDataContext } from 'context/UserContext';

const useConfirm = () => {
  const { user } = useDataContext();

  const confirm = ({ title, message, onConfirm, confirmButtonText }) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            title={title}
            message={message}
            confirmButtonText={confirmButtonText}
            onCancel={onClose}
            user={user}
            onConfirm={() => {
              onConfirm();
              onClose();
            }}
          />
        );
      }
    });
  };

  return confirm;
};

export default useConfirm;
