import React, { useEffect } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { useDataContext } from 'context/UserContext';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { cn, is2FACode, isSecretKey } from 'utils/misc';

function Error({ children }) {
  return <p className="mt-1 text-xs font-medium text-red-500">{children}</p>;
}

Error.propTypes = {
  children: PropTypes.node.isRequired
};

const SECRET_KEY_EXAMPLE =
  'HBRQJX7PTKYPZV3XN6UKNGFBNISWH5MMHFTMVWWONGWOSEJAKB3S';

const SPACED_SECRET_KEY_EXAMPLE = SECRET_KEY_EXAMPLE.match(/.{1,4}/g).join(' ');

function SignIn({
  email,
  setEmail,
  password,
  setPassword,
  setCurrentStep,
  goBackward,
  code,
  setCode,
  codeValid,
  setCodeValid
}) {
  const { user } = useDataContext();

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && language !== 'en-US') {
    language = 'en';
  }

  if (user && user.language) {
    language = user.language;
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const codeIs2FACode = is2FACode(code);
  // Inside your component
  const navigate = useNavigate();
  const is2FACodeError =
    !codeValid || code === '' || code === SECRET_KEY_EXAMPLE;

  const sendToServer = async () => {
    if (!codeValid) {
      toast.error('Invalid 2FA Code');
      setCode('');
      return;
    }

    axios('/accounts', {
      method: 'PUT',
      data: {
        email,
        password,
        twoFactorKey: code,
        isDealAccount: false,
        controls: {
          discount: 100,
          minPrice: 0,
          maxPrice: 1
        }
      }
    })
      .then(res => {
        if (res.status === 200) {
          toast.success('Account added successfully! Attempting to login...');
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 1000);
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 409) {
            toast.error('Account already exists!');
          } else {
            if (err.response.data.error) {
              toast.error(
                err.response.data.error ??
                  'Something went wrong while adding account!'
              );
            } else {
              toast.error(
                'Something went wrong response code: ' +
                  err.response.status +
                  ''
              );
            }
          }
        }
      });
  };

  useEffect(() => {
    if (code.length > 0) {
      if (/^[A-Z2-7]{32,64}$/.test(code)) {
        setCodeValid(true);
      } else {
        setCodeValid(false);
      }
    }
  }, [code, setCodeValid]);

  function SecretKeyExample() {
    return (
      <div>
        <strong className="text-sm font-bold tracking-wide uppercase opacity-60">
          {t('guide.exampleKey')}
        </strong>
        <p className="my-0 text-sm font-medium select-none">
          {SPACED_SECRET_KEY_EXAMPLE}
        </p>
      </div>
    );
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        sendToServer();
      }}
    >
      <p className="pt-0 mt-0">{t('guide.description')}</p>
      <div className="flex flex-col gap-2.5 sm:flex-row">
        <label className="w-full" htmlFor="email">
          <span className="text-sm font-medium">{t('guide.amznEmail')}</span>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder={`${t('guide.amznEmail')}...`}
            id="email"
            required
          />
        </label>
        <label className="mb-2.5 w-full" htmlFor="password">
          <span className="text-sm font-medium">{t('guide.amznPass')}</span>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className=" block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            placeholder={`${t('guide.amznPass')}...`}
            required
            id="password"
          />
        </label>
      </div>
      <label className="w-full" htmlFor="2fa-secret-key">
        <span className="text-sm font-medium">{t('guide.secret2FA')}</span>
        <input
          className={cn(
            'block w-full appearance-none rounded-md border px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm',
            !is2FACodeError ? 'border-gray-300' : 'border-red-300'
          )}
          placeholder={`${t('guide.paste2FAAlert')}`}
          type="text"
          value={code}
          onChange={e => {
            setCode(e.target.value.replaceAll(' ', ''));
          }}
          id="2fa-secret-key"
        />
        {!isSecretKey(code) && code !== '' && (
          <Error>
            {codeIs2FACode
              ? 'This is a 2FA code, not a 2FA secret key'
              : 'Invalid 2FA secret key'}
          </Error>
        )}
        {code === SECRET_KEY_EXAMPLE && (
          <Error>
            This is the example secret key we provided. Please use your own
            secret key.
          </Error>
        )}
      </label>
      <div className="mt-7">
        <SecretKeyExample />
      </div>
      <div className="mt-5 flex w-full justify-between gap-2.5">
        <button
          type="button"
          onClick={goBackward}
          className="flex h-[36px] w-[36px] shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300"
          aria-label="Go backward"
        >
          <FaChevronLeft />
        </button>
        <button
          type="submit"
          className="flex shrink-0 items-center justify-center gap-1.5 rounded-full bg-gray-100 py-2 px-5 text-sm font-medium transition hover:bg-gray-200 active:bg-gray-300 disabled:cursor-not-allowed disabled:hover:bg-gray-100"
          disabled={is2FACodeError}
        >
          {t('guide.startLoginProcess')} <FaChevronRight className="text-xs" />
        </button>
      </div>
    </form>
  );
}

SignIn.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  goBackward: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  codeValid: PropTypes.bool.isRequired,
  setCodeValid: PropTypes.func.isRequired
};

export default SignIn;
