import i18n from 'i18n';
import axios from 'axios';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { LuClipboardCopy, LuLoader2 } from 'react-icons/lu';
import { IoMdCheckmark } from 'react-icons/io';
import { RiLoader5Fill } from 'react-icons/ri';
import { TbClockHour2 } from 'react-icons/tb';
import { copyToClipboard } from 'utils/copyToClipboard';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useDataContext } from 'context/UserContext';
import { FaArrowCircleLeft, FaExclamationCircle } from 'react-icons/fa';
import { TOTP } from 'otpauth';
import { FiChevronRight } from 'react-icons/fi';

const error = props => {
  const { children } = props;
  return <p className="mt-1 text-xs font-medium text-red-500">{children}</p>;
};

const StepWelcome = props => {
  const { handleSubmit } = props;
  // we'll skip this step.

  // we actually remove the step completely from the steps array.
  // But since step index is used hardcoded in the logic so I did not want to break something.
  // TODO: refactor the logic to remove this step completely
  handleSubmit(0);
  return null;
};

const StepSignIn = props => {
  const { language, handleSubmit, errorMessage, setErrorMessage } = props;
  const { t } = useTranslation('', { lng: language });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [twoFactorKey, setTwoFactorKey] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);

  const verifyOtp = () => {
    try {
      const totp = new TOTP({
        secret: twoFactorKey,
        algorithm: 'SHA1',
        digits: 6,
        period: 30
      });

      const isValid = totp.validate({ token: otp, window: 3 });

      if (isValid === null) {
        setErrorMessage(t('error.invalidOtpSetup'));
        return false;
      } else {
        return true;
      }
    } catch (error) {
      setErrorMessage(t('error.errorValidatingOtp'));
      return false;
    }
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="text-gray-600 flex flex-col gap-1 mb-2">
        <div>{t('guide.willUse')}</div>
        <div>{t('guide.encrypted')}</div>
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <label className="flex flex-col gap-1 w-full">
          <span className="font-semibold text-sm">{t('guide.username')}</span>
          <input
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            type="text"
            placeholder={t('guide.username')}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </label>

        <label className="flex flex-col gap-1 w-full">
          <span className="font-semibold text-sm">{t('guide.password')}</span>
          <input
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
            type="password"
            placeholder={t('guide.password')}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </label>
      </div>
      {showTwoFactorInput && (
        <input
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
          type="text"
          placeholder={t('guide.secret2FA')}
          value={twoFactorKey}
          onChange={e => setTwoFactorKey(e.target.value.replace(/\s+/g, ''))}
        />
      )}
      {showOtpInput && (
        <input
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
          type="text"
          placeholder={t('guide.otp')}
          value={otp}
          onChange={e => setOtp(e.target.value)}
        />
      )}
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {!showTwoFactorInput && (
        <button
          onClick={() => {
            setShowTwoFactorInput(true);
            setShowOtpInput(true);
          }}
          className="flex items-center gap-1.5 rounded-full px-5 py-2 text-sm font-medium bg-gray-100 !text-gray-500 transition hover:bg-gray-200 hover:!text-gray-800 active:bg-gray-200 flex-shrink-0"
          type="button"
        >
          <FaExclamationCircle className="flex-shrink-0" />{' '}
          <span className="flex-shrink-0">{t('guide.ikMyKey')}</span>
        </button>
      )}
      {showTwoFactorInput && (
        <button
          onClick={() => {
            setShowTwoFactorInput(false);
            setShowOtpInput(false);
          }}
          className="flex items-center gap-1.5 rounded-full px-5 py-2 text-sm font-medium bg-gray-100 !text-gray-500 transition hover:bg-gray-200 hover:!text-gray-800 active:bg-gray-200 flex-shrink-0 mt-5"
          type="button"
        >
          <FaArrowCircleLeft className="flex-shrink-0" />{' '}
          <span className="flex-shrink-0">{t('guide.back')}</span>
        </button>
      )}
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          if (email === '' || password === '') {
            setErrorMessage(t('guide.pleaseEnter'));
            return;
          }
          if (showTwoFactorInput && twoFactorKey === '') {
            setErrorMessage(t('guide.enter2FAKey'));
            return;
          }

          if (showOtpInput) {
            if (!verifyOtp()) {
              return;
            }
          }

          handleSubmit(1, {
            email,
            password,
            twoFactorKey: showTwoFactorInput ? twoFactorKey : undefined
          });
        }}
      >
        {t('navBar.signIn')}
      </button>
    </div>
  );
};

const StepSigningIn = props => {
  const { language, setStep } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="flex flex-col gap-2 text-gray-600">
      <div className="p-5">
        <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li class="mb-10 ms-6">
            <span class="absolute flex items-center justify-center w-8 h-8 border-2 border-gray-500 bg-gray-100 rounded-full overflow-hidden -start-4 ring-4 ring-white ">
              <RiLoader5Fill className="text-gray-500 animate-spin w-full h-full  rounded-full" />
            </span>
            <h3 class="font-normal text-base leading-tight pt-1">
              {t('guide.signingIn')}
            </h3>
          </li>

          <li class="-mb-2 ms-6">
            <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
              <TbClockHour2 className="text-gray-500 text-2xl" />
            </span>
            <h3 class="font-normal text-base leading-tight pt-1">
              {t('guide.twoMins')}
            </h3>
          </li>
        </ol>
      </div>
      {/* <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(3);
        }}
      >
        Simulate OTP Request
      </button>
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(4);
        }}
      >
        Simulate 2FA Request
      </button>
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          setStep(5);
        }}
      >
        Simulate Authenticator Setup
      </button> */}
    </div>
  );
};

const StepRequestOTP = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [otp, setOtp] = useState('');

  return (
    <div className="flex flex-col gap-5 text-gray-600">
      {context.message && <p>{context.message}</p>}
      <p>{t('guide.enterCode')}</p>
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="text"
        placeholder="OTP"
        onChange={event => {
          setOtp(event.target.value);
        }}
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          handleSubmit(4, otp);
        }}
      >
        {t('guide.submit')}
      </button>
    </div>
  );
};

const StepRequest2FA = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [code, setCode] = useState('');

  return (
    <div className="flex flex-col gap-5 text-gray-600">
      {context.message && <p>{context.message}</p>}
      <p>{t('guide.enterCode')} </p>
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
        type="text"
        placeholder="2FA Code"
        onChange={event => {
          setCode(event.target.value);
        }}
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition"
        onClick={() => {
          handleSubmit(4, code);
        }}
      >
        {t('guide.submit')}
      </button>
    </div>
  );
};

const StepAuthenticator = props => {
  const { language, handleSubmit, errorMessage, context } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  const [code, setCode] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 justify-between">
        <div className="flex flex-col text-gray-600">
          <div className="lg:p-4">
            <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white">
                  <p>1</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight lg:pt-1">
                  {t('guide.getThe')}{' '}
                  <span className="font-bold">{t('guide.free')} </span>{' '}
                  {t('guide.googleAuth')}
                </h3>
              </li>
              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white">
                  <p>2</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight">
                  {t('guide.openApp')}
                </h3>
              </li>
              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white">
                  <p>3</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight">
                  {t('guide.setup')}
                </h3>
              </li>
              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white">
                  <p>4</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight">
                  {t('guide.acctBox')}
                </h3>
              </li>
              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white">
                  <p>5</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight">
                  {t('guide.keyBox')}
                </h3>
                <button
                  onClick={() => {
                    copyToClipboard(context.twoFactorKey);
                  }}
                  className="relative flex items-center justify-between w-full mt-5 bg-gray-100 text-gray-800 py-1 px-2 lg:px-3 rounded-md border border-gray-300 hover:brightness-95 transition"
                >
                  <p className="truncate text-left">
                    {context.twoFactorKey || (
                      <p className="text-gray-600 text-sm">Loading...</p>
                    )}
                  </p>
                  <div className=" right-2 top-0.5 text-gray-400">
                    <LuClipboardCopy size={'1.5rem'} />
                  </div>
                </button>
              </li>

              <li class="mb-7 lg:mb-10 ms-6 lg:ms-8">
                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-500 rounded-full -start-4 ring-4 ring-white ">
                  <p>6</p>
                </span>
                <h3 class="font-normal text-sm lg:text-base pt-1.5 leading-tight">
                  {t('guide.addCode2')}
                </h3>
              </li>
            </ol>
            <div className="space-y-4 mt-8 text-gray-600">
              <p className="text-sm lg:text-base text-gray-600">
                {t('guide.enterCont')}
              </p>
              <input
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm !ring-0 transition focus:border-highlight focus:outline-none sm:text-sm"
                type="text"
                placeholder="2FA Code"
                onChange={event => {
                  setCode(event.target.value);
                }}
              />
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            </div>
          </div>
        </div>

        {isDesktop && context.twoFactorKey && context.email && (
          <div className="flex items-center justify-center mb-4">
            <div className="flex flex-col items-center justify-center gap-4">
              <p className="text-sm text-gray-500">- OR -</p>
              <div className="border rounded-lg shadow-md p-3">
                <QRCodeCanvas
                  value={`otpauth://totp/Amazon:${context.email}?secret=${context.twoFactorKey}&issuer=Amazon`}
                />
              </div>
              <p>{t('guide.scanQr')}</p>
            </div>
          </div>
        )}

        <div className="flex items-center justify-center">
          <button
            onClick={() => {
              setShowVideo(!showVideo);
            }}
            className="flex items-center gap-1.5 rounded-full px-2 lg:px-5 py-2 text-[11px] lg:text-[13px] font-medium bg-gray-100 !text-gray-500 transition hover:bg-gray-200 hover:!text-gray-800 active:bg-gray-200 flex-shrink-0"
            type="button"
          >
            <FaExclamationCircle className="flex-shrink-0" />{' '}
            <span className="flex-shrink-0">
              {showVideo ? t('guide.hideVideo') : t('guide.showVideo')}
            </span>
          </button>
        </div>

        {showVideo && (
          <div className="flex flex-col gap-4">
            <video
              muted
              controls
              autoPlay
              playsInline
              className={
                'mt-2.5  rounded-md object-cover object-top shadow-lg  h-full aspect-[9/16]  bg-gray-300'
              }
              src={
                language === 'en'
                  ? '/videos/desktop/gauth.mp4'
                  : '/videos/desktop/gauth-es.mp4'
              }
            />
          </div>
        )}
      </div>
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition mt-4"
        onClick={() => {
          handleSubmit(5, code);
        }}
      >
        {t('signIn.submit')}
      </button>
    </div>
  );
};

const StepDone = props => {
  const { language, handleSubmit } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="flex flex-col gap-5 text-gray-600">
      <div className="p-4">
        <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
          <li class="mb-10 ms-8">
            <span class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -start-4 ring-4 ring-white ">
              <IoMdCheckmark className="text-green-500 text-lg" />
            </span>
            <h3 class="font-normal lg:pt-1 leading-tight">
              {t('guide.amazonHasBeenEnabled')}
            </h3>
            {/* <p class="text-sm">Step details here</p> */}
          </li>
          <li class="mb-10 ms-8">
            <span class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -start-4 ring-4 ring-white ">
              <IoMdCheckmark className="text-green-500 text-lg" />
            </span>
            <h3 class="font-normal pt-1 leading-tight">
              {t('guide.twoFaHasBeenEnabled')}
            </h3>
          </li>

          <li class="ms-8">
            <span class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -start-4 ring-4 ring-white ">
              <IoMdCheckmark className="text-green-500 text-lg" />
            </span>
            <h3 class="font-normal pt-1 leading-tight">
              {t('guide.youAreReady')}
            </h3>
          </li>
        </ol>
      </div>
      <button
        className="gradient rounded-full w-full p-2 text-white hover:brightness-90 transition hover:animation-pulse"
        onClick={() => {
          handleSubmit(6);
        }}
      >
        {t('guide.goToDash')}
      </button>
    </div>
  );
};

const StepHasNotSeenOnboarding = props => {
  const { language, setHasSeenOnboarding } = props;

  const { t } = useTranslation('', {
    lng: language
  });

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4"> {t('guide.welcome')}</h1>
      <div>
        <p>{t('guide.welcomeBody')}</p>
      </div>
      <div className='mt-4'>
        <img
          src="/onboarding-popup.svg"
          className="w-full rounded-lg lg:h-64"
          alt="Ecommerce store"
        />
      </div>
      <div className="">
        <h4 className="mt-5 mb-0 font-semibold">{t('guide.plsConfirm')}</h4>
        <ol className="list-decimal p-5 space-y-1.5">
          <li>
            {t('guide.amzAcctNeeds')} <strong>{t('guide.defaultShip')}</strong>
          </li>
          <li>
            {t('guide.acctIs')} <strong>{t('guide.not')}</strong> {t('guide.a')}{' '}
            <strong>{t('guide.bizAcct')}</strong>
          </li>
          <li>
            {t('guide.does')} <strong>{t('guide.not')}</strong>{' '}
            {t('guide.haveA')} <strong>{t('guide.gcCredit')}</strong>{' '}
            {t('guide.balance')}
          </li>
          <li>
            {t('guide.acctLng')} <strong>{t('guide.english')}</strong>
          </li>
        </ol>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={() => setHasSeenOnboarding(true)}
          className="rounded-full w-full flex items-center justify-center group gap-1 bg-gradient px-3 py-2 text-white hover:brightness-105 transition mt-5 text-sm font-medium"
        >
          <div className='flex items-center gap-1'>
            {t('home.getStarted')}
            <div className="group-hover:translate-x-1 duration-500 flex items-center justify-center">
              <FiChevronRight className="inline-block text-lg" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default function Steps(props) {
  const {
    language,
    step,
    handleSubmit,
    setStep,
    errorMessage,
    setErrorMessage,
    context
  } = props;

  const { updateUser, user } = useDataContext();

  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(
    Boolean(user?.hasSeenOnboarding)
  );

  const { t } = useTranslation('', {
    lng: language
  });

  const steps = [
    {
      id: 'welcome',
      title: t('guide.welcome'),
      content: <StepWelcome language={language} handleSubmit={handleSubmit} />
    },
    {
      id: 'sign-in',
      title: t('guide.signIn'),
      content: (
        <StepSignIn
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )
    },
    {
      id: 'signing-in',
      title: t('guide.signIn'),
      content: <StepSigningIn setStep={setStep} />
    },
    {
      id: 'request-otp',
      title: t('guide.provOtp'),
      content: (
        <StepRequestOTP
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'request-2fa',
      title: t('guide.prov2fa'),
      content: (
        <StepRequest2FA
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'authenticator',
      title: t('guide.setAuth'),
      content: (
        <StepAuthenticator
          language={language}
          handleSubmit={handleSubmit}
          errorMessage={errorMessage}
          context={context}
        />
      )
    },
    {
      id: 'done',
      title: t('guide.done'),
      content: <StepDone handleSubmit={handleSubmit} />
    }
  ];

  return (
    <div className="w-full">
      {/* card */}
      <div className="relative bg-white w-full rounded-xl px-6 pt-10 lg:px-8 lg:pt-12 pb-8 shadow-lg overflow-hidden">
        {steps[step].id !== 'signing-in' && (
          <div className="absolute left-0 right-0 top-0 gradient h-1" />
        )}

        {steps[step].id === 'signing-in' && (
          <div class="w-full absolute left-0 right-0 top-0 h-1">
            <div class="h-full w-full  overflow-hidden">
              <div class="progress w-full h-full gradient left-right"></div>
            </div>
          </div>
        )}

        {steps[step].id === 'done' && (
          <div class="w-full absolute left-0 right-0 top-0 h-1">
            <div class="h-full w-full overflow-hidden">
              <div class="progress w-full h-full gradient-success left-right"></div>
            </div>
          </div>
        )}

        <div className="flex flex-col justify-between gap-4 h-full">
          {hasSeenOnboarding && (
            <div>
              <h1 className="text-2xl font-bold mb-4">{steps[step].title}</h1>
              {steps[step].content}
            </div>
          )}

          {!hasSeenOnboarding && (
            <div>
              <StepHasNotSeenOnboarding
                language={language}
                setHasSeenOnboarding={setHasSeenOnboarding}
              />
            </div>
          )}

          {/* <div className="flex gap-6 w-min mx-auto mt-8">
            {steps.map((s, i) => {
              return (
                <div
                  onClick={() => {
                    setStep(i);
                  }}
                  className={`w-3 h-3 rounded-full ${
                    s === steps[step] ? 'bg-gray-400' : 'bg-gray-300'
                  }`}
                />
              );
            })}
          </div> */}
        </div>
        <div className="flex justify-end items-center mt-8">
          <select
            className="border-none text-sm text-gray-800"
            name="country"
            id="country"
            defaultValue={language}
            onChange={e => {
              axios('/user', {
                method: 'POST',
                data: {
                  language: e.target.value
                }
              })
                .then(() => {
                  updateUser();
                  toast.success(`${t('guide.changeLangToastSuccess')}`);
                  i18n.changeLanguage(e.target.value).catch(error => {
                    console.error('Error changing language:', error);
                  });
                })
                .catch(e => {
                  const error = e?.response?.data?.error;
                  toast.error(
                    error ??
                    'Something went wrong while updating language preference!'
                  );
                });
            }}
          >
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
        </div>
      </div>
    </div>
  );
}
