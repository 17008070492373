/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  FaCircle,
  FaLock,
  FaPause,
  FaPencilAlt,
  FaPlay,
  FaSpinner,
  FaTrash
} from 'react-icons/fa';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { useDataContext } from 'context/UserContext';
import useConfirm from 'utils/useConfirm';

function getAccountColor(account) {
  if (account.paused) return 'text-yellow-400';
  if (account.metadata?.error) return 'text-rose-400';
  if (!account.metadata?.cookie) return 'text-yellow-400';
  return 'text-emerald-500';
}

function AccountCard({
  index,
  togBot,
  setActiveAccount,
  setEditAccountModal,
  setDeleteAccountModal,
  account,
  AccountStatus,
  isLoading,
  onClickAmazonPrimeWarning,
}) {
  const { user } = useDataContext();
  const confirm = useConfirm();

  let language = 'en';

  if (!user.language) {
    console.log('NO LANGUAGE - SETTING');
    const languagedetector = new LanguageDetector();

    language = languagedetector.detect();

    if (language !== 'es' && !language.includes('en-US')) {
      language = 'en';
    }

    user.language = language;
  }

  const { t } = useTranslation('', {
    lng: user.language || 'en'
  });

  const [moreInfoExpanded] = useState(true);

  const pauseAccount = async account => {
    confirm({
      title: t('dashboard.accountActions.pauseConfirm'),
      message: t('dashboard.accountActions.pauseConfirmMessage'),
      confirmButtonText: t('dashboard.accountActions.pauseConfirmButton'),
      onConfirm: () => {
        setActiveAccount(account);
        togBot(account);
      }
    });
  };

  return (
    <div
      key={account.email + index}
      className="w-full p-5 mx-auto bg-white border rounded-md shadow-sm"
    >
      <div className="flex justify-between">
        <div className="inline-flex w-full items-center gap-1.5 truncate text-sm">
          <FaCircle
            className={clsx(
              'tooltip shrink-0 text-sm',
              isLoading && 'animate-pulse text-yellow-400',
              getAccountColor(account)
            )}
          />
          {account.email}
        </div>
      </div>

      {moreInfoExpanded ? (
        <div className="grid gap-1 mt-1 text-xs text-left text-gray-500">
          <div className="mt-2.5 flex w-full gap-1.5">
            {(account.metadata?.error || !account.metadata?.cookie) &&
              !account.paused ? (
              <button
                disabled
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
                type="button"
              >
                <FaLock className="text-xs" />
                Waiting
              </button>
            ) : !account.paused ? (
              <button
                onClick={() => pauseAccount(account)}
                type="button"
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
              >
                {isLoading ? (
                  <FaSpinner className="text-xs animate-spin" />
                ) : (
                  <FaPause className="text-xs" />
                )}
                {t('dashboard.accountActions.pause')}
              </button>
            ) : (
              <button
                onClick={() => {
                  setActiveAccount(account);
                  togBot(account);
                }}
                type="button"
                className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
              >
                {isLoading ? (
                  <FaSpinner className="text-xs animate-spin" />
                ) : (
                  <FaPlay className="text-xs" />
                )}
                {t('dashboard.accountActions.start')}
              </button>
            )}

            <button
              onClick={() => {
                setActiveAccount(account);
                setEditAccountModal(true);
                window.history.pushState({}, '', '/dashboard/edit');
              }}
              type="button"
              className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
            >
              <FaPencilAlt /> {t('dashboard.accountActions.edit')}
            </button>

            <button
              onClick={() => {
                setActiveAccount(account);
                setDeleteAccountModal(true);
                window.history.pushState({}, '', '/dashboard/delete');
              }}
              type="button"
              className="flex w-full items-center justify-center gap-1.5 rounded-full border px-3.5 py-2.5 text-xs text-gray-500 transition hover:text-gray-800 lg:py-1.5"
            >
              <FaTrash /> {t('dashboard.accountActions.delete')}
            </button>
          </div>

          <div
            title={<AccountStatus account={account} />}
            className="mt-2.5 rounded-md border p-2.5 text-gray-600 shadow-sm"
          >
            <AccountStatus account={account} />{' '}
          </div>

          {!account.hasPrime && (
            <div className="mt-4">
              <div>
                <div className='flex gap-2'>
                  <ExclamationCircleIcon className='h-5 w-5 text-yellow-500' />
                  <div className='text-sm text-gray-500'>
                    <div>{t('dashboard.amazonPrimeWarning.message')}</div>
                  </div>
                </div>
                <button className="text-sm text-gray-400 mt-1 underline ml-7 hover:brightness-90 transition" onClick={onClickAmazonPrimeWarning}>
                  {t('dashboard.amazonPrimeWarning.whatsThis')}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

AccountCard.propTypes = {
  index: PropTypes.number.isRequired,
  togBot: PropTypes.func.isRequired,
  setActiveAccount: PropTypes.func.isRequired,
  setEditAccountModal: PropTypes.func.isRequired,
  setDeleteAccountModal: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  AccountStatus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default AccountCard;
