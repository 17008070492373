import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  es: {
    translation: {
      banners: {
        cancelMsg: 'Su suscripción está programada para cancelarse en',
        uncancel: 'Descancelar?'
      },
      confirmationModal: {
        cancel: 'Cancelar',
        confirm: 'Confirmar'
      },
      home: {
        whyUse: '¿Por qué usar',
        satisfaction: 'Satisfacción garantizada',
        letItflow: 'Deja que fluya el regalo',
        storyBody: `Comenzamos encontrando ofertas y regalos manualmente en Amazon. Buscar constantemente cupones que se apilaran era mucho trabajo, pero persistimos. Hace aproximadamente un año, decidimos aprovechar el poder de la AI para encontrar estos artículos más rápido. Avance rápido hasta ${new Date().getFullYear()} y ahora hemos ahorrado a nuestros clientes millones de dólares. ¿La mejor parte? Apenas estamos comenzando.`,
        story: 'Nuestra historia',
        worth: 'valor de productos descubiertos',
        easyCancel: 'Cancela fácilmente en cualquier momento, sin preguntas.',
        startGetting: '¡Comienza a obtener regalos gratis ahora!',
        whatCanIGet: '¿Qué regalos puedo obtener?',
        liveFeed: 'Transmisión en vivo',
        learnMore: 'Aprende más',
        getStarted: 'Empezar',
        heroTitle: ['Cosas gratis', 'entregadas', 'directamente a su puerta'],
        heroBody: [
          `FreebieFlow es una AI que encuentra `,
          `productos gratuitos de Amazon`,
          ` a través de cupones extremos y los ordena por usted. Hasta ahora, hemos descubierto `,
          `más de $84,000,000 de dólares`,
          ` en cosas gratis para nuestros usuarios.`
        ],
        howItWorks: 'Cómo funciona',
        startReceiving:
          'Comience a recibir grandes descuentos y regalos en unos pocos pasos',
        howItWorksCards: [
          {
            title: 'Vincula tus cuentas',
            body: 'FreebieFlow se conecta de forma segura a tu cuenta de Amazon Prime.'
          },
          {
            title: 'Personalizar',
            body: 'Establezca preferencias para que nuestra AI las cumpla, incluyendo qué tipos de descuentos le gustan.'
          },
          {
            title: 'Relájate',
            body: '¡Tu bot escanea ofertas y regalos las 24 horas del día, los 7 días de la semana, y los reclama mientras duermes!'
          }
        ],
        savedPerMonth: 'guardado por usuario por mes (promedio)',
        freebiesDelivered: 'productos gratuitos entregados',
        see: 'Ver',
        inAction: 'en acción',
        watchOnTikTok: 'Ver en TikTok',
        startGettingFreebies: 'Comienza a recibir regalos gratis ahora!',
        whyUseFreebieFlow: {
          zeroHassles: {
            title: 'Cero problemas',
            body: 'Siéntase libre de cancelar o pausar su suscripción en cualquier momento, sin preguntas. ¡Simplemente haga clic en el botón Cancelar en nuestro tablero!'
          },
          ultimateSavings: {
            title: 'Ahorros definitivos',
            body: 'Con nuestra selección de regalos, obtendrá toneladas de artículos divertidos cada mes. ¿Quieres ahorrar aún más? También puede configurar Freebie Flow para revisar artículos con descuentos muy altos.'
          },
          exclusiveAccess: {
            title: 'Acceso exclusivo',
            body: 'Obtenga acceso a la comunidad FreebieFlow y disfrute de acceso exclusivo a productos gratuitos de alta calidad, seleccionados por nuestra AI.'
          }
        },
        features: {
          expertSupport: {
            title: 'Soporte experto',
            body: 'Benefíciese de nuestro equipo de soporte experto, siempre disponible para responder sus preguntas y ayudarlo a configurarse rápidamente.'
          },
          moneyBackGuarantee: {
            title: 'Garantía de devolución de dinero',
            body: 'Con nuestra garantía de devolución de dinero, aseguramos un reembolso si no obtiene con éxito un regalo gratis en su primer mes.'
          },
          membershipPanel: {
            title: 'Panel de membresía',
            body: 'Administre fácilmente su suscripción con nuestro panel de membresía, que proporciona acceso a servicios de administración de cuentas, historial de facturación y un botón de cancelación con un clic.'
          },
          productDiscovery: {
            title: 'Descubrimiento de productos 24/7',
            body: 'Nuestro potente bot escanea 1.2 millones de productos todos los días, trabajando las 24 horas del día para encontrar las mejores ofertas y regalos disponibles.'
          }
        },
        everything: 'Todo lo que necesitas',
        everythingBody:
          'Con nuestra tecnología avanzada, asegurar increíbles descuentos en línea nunca ha sido tan fácil. Simplemente configure sus parámetros de descuento y deje que nuestro software se encargue del resto, incluso comprando los artículos con descuento por usted.',
        customersSaying: 'Lo que dicen nuestros clientes',
        or: 'o',
        whatsIncluded: 'Qué se incluye',
        faq: {
          question1: {
            question:
              '¿Cómo puede FreebieFlow enviar tantos paquetes a mi casa gratis?',
            answer:
              'Nuestro software rastrea todo Internet en busca de códigos de cupones que se acumulan para que el producto tenga un costo final de cero dólares. Estos códigos de cupones a menudo provienen de vendedores que están descontando su producto a cero para aumentar su ranking de ventas o hacer espacio para nuevas existencias.'
          },
          question2: {
            question:
              '¿Por qué necesito emparejar el software con mi cuenta de Amazon?',
            answer:
              'Nos asociamos con su cuenta de Amazon para enviar artículos que se envían directamente desde Amazon para garantizar un envío rápido y gratuito. Si elige pagar artículos con grandes descuentos, esto también le permite tener devoluciones gratuitas. Tenga la seguridad de que la seguridad de sus datos es nuestra prioridad número 1 y sus datos están encriptados y nunca se comparten.'
          },
          question3: {
            question:
              '¿Qué tipo de artículos envía FreebieFlow y con qué frecuencia?',
            answer:
              'FreebieFlow es conocido por enviar una variedad diversa de productos, ¡manteniendo la experiencia de desempaquetar una emoción! Algunos artículos que hemos enviado en el pasado incluyen drones, aspiradoras robot, relojes inteligentes y mucho más. Aunque la frecuencia con la que enviamos los artículos es aleatoria, ¡puede esperar alrededor de 10 a 30 artículos al mes!'
          },
          question4: {
            question: '¿Cómo funciona el pago?',
            answer:
              'Solo paga nuestro costo de suscripción mensual. Nunca tendremos tarifas ocultas. ¡No importa cuántos artículos gratuitos le enviemos, siempre será el mismo precio!'
          },
          question5: {
            question: '¿Qué pasa si quiero cancelar o obtener un reembolso?',
            answer:
              'Puede cancelar su suscripción en cualquier momento. Una vez que cancele, no se le cobrará el próximo mes y su suscripción finalizará al final de su ciclo de facturación.'
          },
          question6: {
            question: '¿Funciona FreebieFlow en cualquier país?',
            answer:
              'FreebieFlow requiere una dirección en los EE. UU. para enviar artículos gratis.'
          }
        },
        cantFind: `¿No encuentra la respuesta que busca? Comuníquese con nuestro `,
        customerSupport: 'soporte al cliente',
        team: 'grupo.',
        readyToDiveIn: '¿Listo para sumergirse?',
        readyToReceive: 'Comience a recibir regalos gratis hoy.',
        couponBanner: {
          title:
            '¡Bienvenido a FreebieFlow! ¡Obtenga $10 de descuento en su primer mes usando el código',
          code: 'SPRING10',
          atCheckout: 'al finalizar la compra!'
        },
        claimNow: 'Reclamar ahora'
      },
      navBar: {
        dashboard: 'Tablero',
        settings: 'Ajustes',
        telegram: 'Únete a nosotros en Telegram',
        warehouse: 'Almacén',
        refer: '¡Referir a un amigo y obtén $10!',
        features: 'Características',
        how_it_works: 'Cómo funciona',
        latest_deals: 'Últimas ofertas',
        testimonials: 'Testimonios',
        faq: 'Preguntas frecuentes',
        pricing: 'Precios',
        signIn: 'Registrarse'
      },
      dashboard: {
        cancelsOn: 'Cancela en',
        currentPlan: 'Plan actual',
        setCancel: 'establecer para cancelar en',
        renewsOn: 'se renueva en',
        niceJob: '¡🙂 Buen trabajo!',
        savedTotal: [
          'En total, has ahorrado',
          ' y recibido',
          ['¡Este mes solo has recuperado tu suscripción mensual', 'veces más!']
        ],
        chartData: {
          pastWeek: 'Semanas pasadas',
          monthly: 'Mensual',
          overTime: 'Tus ahorros con el tiempo',
          moneySaved: 'Dinero ahorrado'
        },
        canceledDisclaimer:
          'Nota: estas estadísticas solo tienen en cuenta los paquetes entregados.',
        noHits: 'Aún no hay resultados. ¡Vuelva más tarde!',
        recentHits: 'Tus éxitos recientes',
        filterValues: [
          'Todos',
          'Llegando',
          'Esperado',
          'Pendiente',
          'Entregado'
        ],
        search: 'Buscar...',
        yourAccount: 'Tu cuenta',
        buyMore: 'Compra más espacios de cuenta.',
        addFreebie: '¡Haz clic para agregar una cuenta de Freebie!',
        addDeal: '¡Haz clic para agregar una cuenta de trato!',
        accountActions: {
          start: 'Comienzo',
          pause: 'Pausa',
          edit: 'Editar',
          delete: 'Eliminar',
          pauseConfirm: '¿Estás seguro de que quieres pausar esta cuenta?',
          pauseConfirmMessage:
            'Tenga en cuenta que pausar una cuenta solo impide que pueda retirar obsequios. Su suscripción seguirá activa y cargada. Visite la página de configuración para consultas relacionadas con la facturación.',
          pauseConfirmButton: 'Pausar la cuenta de Amazon'
        },
        active: 'FreebieFlow está activo. Tus ajustes solo permiten',
        active2:
          'FreebieFlow está activo. Tus ajustes permiten todos los regalos y ofertas con al menos un',
        loggingIn: [
          'Iniciando sesión... Esto suele tardar hasta 2 minutos. La página se actualizará automáticamente cuando termine.',
          'Iniciando sesión...'
        ],
        contactSupport: 'Contacta con soporte para obtener ayuda',
        toastMsgs: {
          active: 'Tu cuenta ahora está activa.',
          inactive: 'Tu cuenta ahora está inactiva.'
        },
        pressStart: '¡Presiona iniciar para asegurar tus regalos!',
        discount: 'descuento',
        maxPriceOf: 'precio máximo de',
        and: 'y',
        searching: `Buscando ofertas...`,
        errorConnectingYourAccount: 'Error al conectar tu cuenta de Amazon ',
        figureItOutMyself: 'Lo resolveré por mi cuenta',
        askSupport: 'Preguntar al soporte',
        continueSetup: 'Continue la configuracion',
        amazonPrimeWarning: {
          message: 'Sin Prime: ¡puede que te lo estés perdiendo!',
          whatsThis: '¿Qué es esto?',
          modal: {
            title: 'Advertencia: ¡puede que te lo estés perdiendo!',
            description:
              'Hemos detectado que tu cuenta no tiene Amazon Prime. Para aprovechar al máximo FreebieFlow, su cuenta debe tener Amazon Prime para obtener envío gratuito.',
            buttons: {
              ok: 'Reconocer',
              contactSupport: 'Soporte de contacto'
            }
          }
        }
      },
      settings: {
        freebieAccts: 'Cuenta(s) de Freebie',
        dealAccts: 'Cuenta(s) de trato',
        tgAccess: 'Acceso a la comunidad de Telegram',
        somethingWentWrong: '¡Algo salió mal!',
        successPromo: '¡Código promocional creado exitosamente!',
        copyPopup: '¡Copiado al portapapeles!',
        enterPromo: 'Código Promocional',
        customDesc:
          '¡Crea un código de promoción personalizado para ofrecerle a tu amigo $10 de descuento en su primer mes!',
        customPromocode: 'Código Promocional Personalizado',
        create: 'Crear',
        updateAcctInfo: 'Actualizar información de la cuenta',
        updateSub:
          'Vea o actualice la información sobre su cuenta FreebieFlow, como su correo electrónico y contraseña.',
        currentPassword: 'Contraseña actual',
        changeEmail: 'Cambiar dirección de correo electrónico',
        newPassword: 'Nueva contraseña',
        enterNewPassword: 'Introduzca la nueva contraseña',
        confirmPassword: 'Confirmar contraseña',
        updateInfo: 'Actualizar información',
        lengthWarning:
          'La contraseña debe tener al menos 8 caracteres de longitud.',
        upperWarning: 'Use al menos una letra mayúscula y un número.',
        referralProgram: 'Programa de referencia',
        referralProgramInfo:
          '¡Inscríbase en nuestro programa de referencias proporcionándonos su correo electrónico de PayPal!',
        visitorCount: 'Contador de visitantes',
        peopleVisited: 'Personas que han visitado su enlace de referencia.',
        purchases: 'Compras',
        peoplePaid:
          'Personas que han pagado por una suscripción utilizando su enlace de referencia.',
        shareLink:
          '¡Comparte tu enlace de referencia personalizado y gana $10 por usuario que pague!',
        copy: 'Copiar',
        copyYourReferral: 'Copia tu enlace de referencia',
        yourCouponCode: 'Su código de cupón',
        enroll: 'Inscribirse',
        paypalEmail: 'Correo electrónico de PayPal',
        paypalInfo:
          'Este es el correo electrónico de PayPal al que le gustaría recibir pagos.',
        totalEarnings: 'Ganancias totales',
        totalEarningsInfo:
          'Esta es la cantidad total de dinero que ha ganado de sus referencias.',
        backToDashboard: 'Volver al tablero',
        update: 'Actualizar',
        yourPlan: 'Tu plan',
        viewCurrPlan:
          'Vea su plan actual o actualice su plan para obtener acceso a más funciones.',
        plans: {
          Basic: 'Básico',
          Pro: 'Pro',
          Family: 'Familia'
        },
        showMore: 'Mostrar más',
        updatePlan: 'Actualizar plan',
        manageBilling: 'Administrar facturación',
        cancelSub: 'Cancelar suscripción',
        uncancelSub: 'Eliminar Cancelar',
        logout: 'Cerrar sesión',
        referralProgramUi: {
          getPaidCash10: 'Recibe $10 en efectivo',
          perPersonYourRefer: 'Por cada persona que recomiendes',
          validEmail:
            'Por favor, introduce una dirección de correo electrónico válida.',
          description:
            '¡No hay límites sobre cuántas personas puedes recomendar! ¡Obtén tu propio código de cupón personalizado que puedes compartir para darles a tus amigos/seguidores un descuento de $10!'
        }
      },
      register: {
        reg: 'Registro',
        regAccount: 'Regístrese para obtener una cuenta',
        regBody:
          '¡Empezar es fácil! Simplemente regístrese una cuenta, suscríbase, vincule su Amazon Prime y comience a obtener productos gratuitos de inmediato.',
        firstName: 'Nombre de pila',
        lastName: 'Apellido',
        emailAddress: 'Dirección de correo electrónico',
        password: 'Contraseña',
        confirmPassword: 'Confirmar contraseña',
        iAgree: 'Estoy de acuerdo con los',
        terms: 'Términos de servicio',
        acknowledgeLang: 'Reconozco que este sitio web está en inglés.',
        info: 'Información',
        linkAcctNow: '¿Necesito vincular mi cuenta ahora?',
        linkBody:
          'Sí, necesitará vincular su cuenta de Amazon Prime para recibir productos gratuitos.',
        payReq: '¿Necesito pagar ahora?',
        payReqBody:
          'Después de registrarse, podrá agregar su información de pago y suscribirse a un plan. Podrás utilizar FreebieFlow inmediatamente después de suscribirte',
        did: '¿Ya hice esto?',
        onAvg: 'En promedio, nuestros miembros reciben su primer obsequio ',
        withinWeek: 'dentro de una sola semana',
        afterSignup: 'después de registrarse.',
        checkEmail: 'Revisa tu correo electrónico',
        clickVerify:
          'Haga clic en el enlace de verificación en el correo electrónico que le enviamos',
        clickVerify2:
          'continuar. Si no ve un correo electrónico, asegúrese de revisar sus carpetas promocionales y de spam.',
        resend: 'Reenviar',
        emailSent: 'Correo electrónico enviado',
        youHave: '¡Tienes',
        waitingForYou: 'regalos esperándote!'
      },
      signIn: {
        success: '¡Iniciado sesión con éxito!',
        typeEmail: 'Escriba su correo electrónico...',
        forgotPassword: 'Olvidó su contraseña',
        email: 'Correo electrónico...',
        whatIsEmail: '¿Cuál es su dirección de correo electrónico?',
        submit: 'Enviar',
        signIn: 'Registrarse',
        signInto: 'Iniciar sesión en su cuenta',
        forgotPassword: '¿Olvidó su contraseña?'
      },
      plans: {
        subNow: 'Suscríbete ahora para empezar a recibir',
        freeStuff: 'cosas gratis entregadas directamente a tu puerta',
        downGrade: 'Puede actualizar o degradar en cualquier momento.',
        easyAI:
          'Nuestro software de inteligencia artificial fácil de usar escanea millones de productos en busca de obsequios y descuentos masivos y los entrega en su puerta con envío gratuito.',
        subTo: 'Suscribirse a',
        features: [
          'Integración de Amazon Prime',
          'Soporte 24/7',
          'Garantía de devolución de dinero'
        ]
      },
      reviews: [
        {
          body: 'He estado usando freebie flow por menos de un mes y me encanta. No esperaba recibir tantos paquetes. La cantidad de dinero que he ahorrado es increíble y seguiré usando freebie flow.',
          name: 'Liz M.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: '¡Amo Freebie Flow! He estado aquí desde abril y no me arrepiento. Puedo dormir mientras FF trabaja para mí. Es como mi asistente personal. He ahorrado mucho dinero. A mi familia también le encanta FF, ¡también están recibiendo regalos! Y por último, pero no menos importante, ¡el servicio al cliente es INCREÍBLE!',
          name: 'Yaishirette P.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: 'Me acerqué a Freebie Flow con escepticismo, pero después de solo un mes, me encontré disfrutando de más de 70 paquetes fantásticos gratis y con descuento!!!!! Ha sido una emoción absoluta recibir estos paquetes sorpresa, cada uno añadiendo una chispa de emoción a mi día.',
          name: 'Brittany Kalinowski',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: 'Soy un nuevo suscriptor y ya estoy contento con mi experiencia hasta ahora. Ya se me han ordenado 3 productos teniendo la cuenta un día o 2. Y el servicio al cliente ha sido realmente útil y receptivo también',
          name: 'Randi T.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: 'Debo decir que al principio estaba un poco indeciso. Pensé que esto es demasiado bueno para ser verdad. Pero una vez que lo probé con la ayuda del increíble equipo de servicio al cliente en septiembre pasado. Me enamoré de todas las sorpresas que recibo semanalmente. ¡Estoy enganchado!',
          name: 'Deb B.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: 'He estado haciendo FreebieFlow durante aproximadamente un mes pero ya recibí mucho más de lo que cubre el costo de la suscripción. Su servicio al cliente también es realmente receptivo. ¡Es realmente divertido recibir sorpresas cada semana!',
          name: 'Melinda K.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: 'Estaba extremadamente indeciso pero tuve un momento en el que simplemente decidí probarlo y terminé obteniendo mi primer regalo al día siguiente! Ahora estoy en el cuarto día y tengo 4 regalos valorados en más de $120! Si estás indeciso, ¡solo pruébalo! ¡Estoy amando esto y estoy muy contento de haberme lanzado!',
          name: 'Mermie E.',
          details: 'Cliente de FreebieFlow'
        },
        {
          body: '¡Solo he estado probando freebie flow durante una semana y hasta ahora todo va bien! ¡He estado recibiendo una cantidad decente de artículos! Ojalá lo hubiera descubierto antes. ¡Es divertido y emocionante ver qué nuevos paquetes puedes abrir todos los días! ¡100% vale la pena! ¡Tienen un excelente servicio y una gran configuración de sitio web y telegram! ¡Altamente recomendado!',
          name: 'Rachel B.',
          details: 'Usuario de FreebieFlow'
        },
        {
          body: 'Esta es una aplicación bastante genial. Puedes seleccionar cuánto te gustaría gastar o ahorrar. Está lleno de sorpresas y eso me gusta. ¡Pensando en actualizar solo por diversión! Pruébalo durante el primer mes.',
          name: 'Kitty L.',
          details: 'Usuario de FreebieFlow'
        },
        {
          body: 'Este sitio web es tan fácil y emocionante. Todo lo que haces es pagar por el mes a un precio razonable, luego revisas todos los días para ver qué golpeó. Esto es algo que todos necesitan probar, ¡es genial!',
          name: 'Megan S.',
          details: 'Fan de FreebieFlow'
        },
        {
          body: 'No necesitas esperar para obtener increíbles ofertas con Freebies Flow; son fáciles de acceder y hacen posibles ahorros increíbles!',
          name: 'Shannon W.',
          details: 'Usuario frecuente de Freebie'
        }
      ],
      editing: {
        confirm: '¡Sí, bórralo!',
        delete: 'Borrar cuenta',
        warning: 'Esto eliminará permanentemente su cuenta.',
        saveChanges: 'Guardar cambios',
        editAcct: 'Editar cuenta',
        youreEditing: 'Estás editando la configuración de',
        passwordTip: 'Deje en blanco si no desea cambiar su contraseña.',
        discPercent: 'Porcentaje de descuento',
        discTip:
          'Esto es lo que FreebieFlow buscará como mínimo en un artículo con descuento.',
        maxPrice: 'Precio máximo',
        maxTip: 'Esto es lo máximo que gastará en un artículo con descuento.',
        minPrice: 'Precio mínimo',
        minTip: 'Esto es lo mínimo que gastará en un artículo con descuento.',
        advanced: 'Configuración avanzada',
        advSettings: {
          instructions: 'Instrucciones',
          secret: 'Secreto 2FA',
          secretTip: 'Habilitar la autenticación de dos factores',
          blacklist: 'Lista negra',
          blacklistTip: 'Palabras clave para excluir de las búsquedas',
          blackPlaceholder:
            'Ingrese la palabra clave o ASIN, separados por comas'
        }
      },
      guide: {
        back: 'Atrás',
        provOtp: 'Proporcionar código OTP',
        prov2fa: 'Proporcionar código 2FA',
        setAuth: 'Configurar 2FA',
        allDone: '¡Todo listo!',
        enterCont: 'Ingrese el código aquí para continuar.',
        addCode2: 'Presione "agregar código".',
        keyBox: 'En el cuadro de claves, pegue esta clave secreta.',
        acctBox: 'En el cuadro de cuenta escriba "Amazon".',
        setup: 'Seleccione "ingresar una clave de configuración".',
        openApp:
          'Abra la aplicación, presione el ícono "+" para crear un nuevo código.',
        googleAuth: 'Aplicación "Google Authenticator" en su teléfono.',
        getThe: 'Consigue el',
        free: 'GRATIS',
        goToDash: 'Ir al panel de control',
        done: '¡Ya está todo configurado y listo para comenzar!',
        scanQr: 'Escanea este código QR con tu aplicación TOTP',
        submit: 'Enviar',
        enterCode: 'Ingrese el código aquí para continuar.',
        signingIn: 'Iniciando sesión...',
        twoMins: '¡Esto puede tardar hasta 2 minutos, por favor ten paciencia!',
        username: 'El correo de tu cuenta de Amazon',
        password: 'Contraseña',
        pleaseEnter:
          'Por favor, introduzca su correo electrónico y contraseña.',
        encrypted:
          '¡Toda su información está cifrada y almacenada de forma segura!',
        willUse:
          'FreebieFlow utilizará tu correo electrónico y contraseña de Amazon para vincular automáticamente tu cuenta.',
        tenMins: 'Estos pasos deberían tomar alrededor de 10 minutos.',
        letsLink:
          '¡Vamos a vincular tu cuenta de Amazon para que podamos comenzar a enviarte cosas gratis!',
        signIn: 'Iniciar sesión en Amazon',
        welcome: '¡Bienvenido a FreebieFlow!',
        scanQR: 'Selecciona "Escanear un código QR"',
        scanQRPage: 'Escanee el QR en su página de Amazon',
        next: 'Siguiente',
        getMyKey: 'Obtener mi clave',
        amznEmail: 'Correo electrónico de Amazon',
        amznPass: 'Contraseña de Amazon',
        secret2FA:
          'Clave secreta de 2FA (se ve así: "M8LY SULH LAT2 GAG3 6BN3 NO0Q 4MNO WHY9 O4MM ANLV 75GE GZKR O5GA")',
        otp: 'OTP (código de 6 dígitos generado en tu aplicación de autenticación)',
        paste2FA: 'Pega tu clave secreta 2FA de antes...',
        startLoginProcess: 'Iniciar proceso de inicio de sesión',
        description:
          'Freebie Flow requiere el correo electrónico y la contraseña de su cuenta de Amazon para poder retirar artículos en su nombre. Toda la información de la cuenta está cifrada y almacenada de forma segura en la nube.',
        signInAmzn: 'Iniciar sesión en Amazon',
        cooldown:
          'Su aplicación de autenticación actualizará códigos de 6 dígitos cada 30 segundos.',
        list: [
          'Ahora verás un código de 6 dígitos generado en tu aplicación de autenticación que cambia cada 30 segundos',
          'Ingresa el código de 6 dígitos en el cuadro en Amazon',
          'Selecciona “Verificar OTP y continuar”',
          'Haz clic en la casilla “no requiere OTP”',
          'Selecciona “Entendido. Activar la verificación en dos pasos”'
        ],
        complete2Step: 'Configuración completa de 2 pasos',
        note: 'Nota: no es necesario pagar por Google Authenticator; es una aplicación gratuita.',
        addCode: 'Presione agregar código',
        boxPaste: 'En el cuadro de claves, pegue la clave secreta 2FA',
        acctBox: 'En el cuadro de cuenta escriba "Amazon"',
        setupKey: 'Seleccione "ingresar una clave de configuración"',
        addIcon: 'Haga clic en el icono “+” para agregar un código',
        getThe: 'Obtén el',
        freeGoogle: 'Aplicación “Google Authenticator” GRATIS',
        onYourPhone: 'en tu teléfono',
        downGoogle: 'Descargar el autenticador de Google',
        save2FA: '¡Su clave secreta 2FA es importante! Guárdalo para después.',
        paste2FAAlert: '¡Pegue su clave secreta 2FA en un lugar seguro!',
        copy2FA: 'Copie su clave secreta 2FA',
        cantBarcode: 'Haga clic en "¿No puede escanear el código de barras?"',
        authSelect: 'Seleccione la opción de la aplicación Autenticador',
        yellowBtn: 'Haga clic en el botón amarillo "Comenzar"',
        setup2FA: 'Configurar autenticación de dos factores',
        clickDisable: 'Haga clic en desactivar',
        checkbox: 'Marque la casilla de verificación',
        disableBtn: 'Haga clic en el botón desactivar',
        thisLink: 'este enlace',
        goTo: 'Ir a',
        ensureDisabled:
          'Asegúrese de que la autenticación de 2 factores esté desactivada',
        videoTutorial: 'Tutorial en video',
        exampleKey: 'EJEMPLO CLAVE SECRETA 2FA',
        acctLng: 'El idioma de la cuenta debe configurarse en',
        balance: 'saldo',
        gcCredit: 'Tarjeta de regalo/crédito',
        letsGetStarted: '¡Empecemos!',
        processTen:
          'Este proceso toma alrededor de 10 minutos, pero si sigues las instrucciones correctamente, ¡recibirás cajas muy pronto!',
        amznTwo: 'Las claves secretas de Amazon 2FA se ven así:',
        amzAcctNeeds: 'Tu cuenta de Amazon necesita un',
        defaultShip: 'dirección de envío y método de pago predeterminados',
        acctIs: 'La cuenta es',
        not: 'NO',
        a: 'una',
        english: 'Inglés o Español',
        bizAcct: 'cuenta de negocios',
        welcome: '¡Bienvenido a FreebieFlow!',
        welcomeBody:
          '¡Estamos emocionados de tenerte en la comunidad! Sigue estos pasos para configurar y aprovechar al máximo tu suscripción.',
        plsConfirm: 'Primero, confirma lo siguiente:',
        amzAcctNeeds: 'Tu cuenta de Amazon necesita un',
        defaultShip: 'dirección de envío y método de pago predeterminados',
        acctIs: 'La cuenta es',
        not: 'NO',
        a: 'una',
        does: 'Hace',
        ikMyKey: 'Ya sé mi clave 2FA',
        haveA: 'tener un',
        bizAcct: 'cuenta de negocios',
        welcome: '¡Bienvenido a FreebieFlow!',
        welcomeBody:
          '¡Estamos emocionados de tenerte en la comunidad! Siga estos pasos para configurar y aprovechar al máximo su suscripción.',
        plsConfirm: 'Primero, confirme lo siguiente:',
        changeLangToastSuccess:
          '¡Preferencia de idioma actualizada correctamente!',
        amazonHasBeenEnabled: '¡Amazon ha sido habilitado con éxito!',
        twoFaHasBeenEnabled: '¡2FA ha sido habilitado con éxito!',
        youAreReady: '¡Estás listo para partir!',
        showVideo: 'Mire el vídeo para ver cómo configurar el autenticador',
        hideVideo: 'Ocultar Vídeo'
      },
      error: {
        invalidCredentials:
          'Las credenciales de tu cuenta de Amazon son inválidas. Por favor, inténtalo de nuevo. [C]',
        twoFaInvalidCredentials:
          'Las credenciales de tu cuenta de Amazon son inválidas. Por favor, inténtalo de nuevo. [C]',
        invalidPassword:
          'La contraseña de tu cuenta de Amazon es inválida. Por favor, elimina y vuelve a añadir la cuenta con la contraseña correcta. [P]',
        twoFaInvalidPassword:
          'La contraseña de tu cuenta de Amazon es inválida. Por favor, elimina y vuelve a añadir la cuenta con la contraseña correcta. [P]',
        invalidEmail:
          'El correo electrónico o nombre de usuario de tu cuenta de Amazon es inválido. Por favor, inténtalo de nuevo. [E]',
        twoFaInvalidEmail:
          'El correo electrónico o nombre de usuario de tu cuenta de Amazon es inválido. Por favor, inténtalo de nuevo. [E]',
        invalidMfa:
          '¡El secreto 2FA de tu cuenta de Amazon es inválido! Por favor, sigue la guía en video. Contacta al soporte si el problema persiste. [E]',
        sessionError:
          'Ocurrió un error al iniciar sesión. Contacta al soporte si el problema persiste después de intentarlo de nuevo. [S]',
        generalError:
          'Ocurrió un error al iniciar sesión. Contacta al soporte si el problema persiste después de intentarlo de nuevo. [G]',
        accountOnHold:
          'Tu cuenta de Amazon está en espera. Por favor, resuélvelo iniciando sesión manualmente [H]',
        accountLocked:
          'Tu cuenta de Amazon está bloqueada. Por favor, resuélvelo iniciando sesión manualmente [H]',
        manualMfa:
          'Tu 2FA no ha sido completamente configurado. Por favor, mira el video tutorial en nuestra sección de ayuda. Puede que no hayas habilitado completamente el 2FA en el sitio web de Amazon. [M]',
        requiresPasswordReset:
          'Tu cuenta de Amazon requiere un restablecimiento de contraseña. Por favor, resuélvelo iniciando sesión manualmente [R]',
        invalidOtpSetup:
          'Tu código de 6 dígitos es incorrecto. Contacta al soporte si necesitas ayuda',
        errorValidatingOtp: 'Error al validar el OTP',
        invalidOtp:
          'Código OTP inválido. Por favor, vuelve a añadir la cuenta y asegúrate de proporcionar el código 2FA correcto. [O]',
        twoFaInvalidOtp:
          'Código OTP inválido. Por favor, vuelve a añadir la cuenta y asegúrate de proporcionar el código 2FA correcto. [O]',
        noPrime:
          'Tu cuenta de Amazon no tiene Amazon Prime. Por favor, actualiza y vuelve a sincronizar la cuenta. [N]',
        notUsa:
          'Tu cuenta de Amazon no es una cuenta de EE. UU. Por favor, actualiza y vuelve a sincronizar la cuenta. [U]',
        notUsaAddress:
          'Tu cuenta de Amazon no tiene una dirección de envío en EE. UU. Por favor, actualiza y vuelve a sincronizar la cuenta. [A]',
        noDefaultAddress:
          'Tu cuenta de Amazon no tiene una dirección de envío predeterminada. Por favor, actualiza y vuelve a sincronizar la cuenta. [D]',
        noDefaultPaymentMethod:
          'Tu cuenta de Amazon no tiene un método de pago predeterminado. Por favor, actualiza y vuelve a sincronizar la cuenta. [M]',
        notEnglish:
          'Tu cuenta de Amazon no tiene el inglés o el español configurado como idioma. Por favor, actualiza y vuelve a sincronizar la cuenta. [L]',
        noDefaultOneClick:
          'Tu cuenta de Amazon no tiene una dirección de envío predeterminada ni un método de pago predeterminado establecido. Por favor, actualiza y vuelve a sincronizar la cuenta.',
        businessAccount:
          'No admitimos cuentas de Amazon Business. Por favor, añade una cuenta regular de Amazon Prime. [B]',
        errdesc: 'Error al añadir la cuenta de Amazon: ',
        twoFaOld2faRequested: 'No completó la configuración de la cuenta.',
        twoFaMfaRequested: 'No completó la configuración de la cuenta.'
      }
    }
  },
  en: {
    translation: {
      banners: {
        cancelMsg: 'Your subscription is set to cancel in',
        uncancel: 'Uncancel?'
      },
      confirmationModal: {
        cancel: 'Cancel',
        confirm: 'Confirm'
      },
      home: {
        whyUse: 'Why use',
        satisfaction: 'Satisfaction guaranteed',
        letItflow: 'Let the freebies flow',
        storyBody: `We started out finding deals and freebies manually on Amazon. Constantly looking for coupons that stacked was a lot of work, but we persisted. About a year ago, we decided to leverage the power of AI to find these items faster. Fast forward to ${new Date().getFullYear()} and we've now saved our customers millions of dollars. The best part? We're just getting started.`,
        story: 'Our Story',
        worth: 'worth of products discovered',
        easyCancel: 'Easily cancel at any time, no questions asked.',
        startGetting: 'Start getting freebies now!',
        whatCanIGet: 'What Freebies Can I Get?',
        liveFeed: 'Live Feed',
        learnMore: 'Learn More',
        getStarted: 'Get Started',
        heroTitle: ['Free stuff', 'delivered', 'right to your doorstep'],
        heroBody: [
          'FreebieFlow is an AI that finds ',
          'free Amazon products',
          ' through extreme couponing and orders them for you. So far, we’ve discovered ',
          'over $84,000,000 worth',
          ' of free stuff for our users.'
        ],
        howItWorks: 'How It Works',
        startReceiving:
          'Start receiving huge discounts and freebies in a few steps',
        howItWorksCards: [
          {
            title: 'Link Your Accounts',
            body: 'FreebieFlow securely connects to your Amazon Prime account.'
          },
          {
            title: 'Customize',
            body: 'Set preferences for our AI to abide by, including what types of discounts you like.'
          },
          {
            title: 'Sit Back and Relax',
            body: 'Your bot scans for deals and freebies 24/7, and claims them while you sleep!'
          }
        ],
        savedPerMonth: 'saved per user per month (avg)',
        freebiesDelivered: 'free products delivered',
        see: 'See',
        inAction: 'in action',
        watchOnTikTok: 'Watch on TikTok',
        startGettingFreebies: 'Start getting freebies now!',
        whyUseFreebieFlow: {
          zeroHassles: {
            title: 'Zero Hassles',
            body: 'Feel free to cancel or pause your subscription at any time, with no questions asked. Just click the Cancel button on our dashboard!'
          },
          ultimateSavings: {
            title: 'Ultimate Savings',
            body: "With our freebie selection, you'll be getting tons of fun items every month. Want to save even more? You can also configure Freebie Flow to check out highly discounted items"
          },
          exclusiveAccess: {
            title: 'Exclusive Access',
            body: 'Get access to the FreebieFlow community and enjoy exclusive access to high-quality free products, handpicked by our AI.'
          }
        },
        features: {
          expertSupport: {
            title: 'Expert support',
            body: 'Benefit from our knowledgeable support team who are always available to answer your questions and assist you in setting up quickly.'
          },
          moneyBackGuarantee: {
            title: 'Money back guarantee',
            body: "With our money back guarantee, we ensure a refund if you don't successfully obtain a freebie in your first month."
          },
          membershipPanel: {
            title: 'Membership panel',
            body: 'Effortlessly manage your subscription with our membership panel, which provides access to account management services, billing history, and a one-click cancellation button.'
          },
          productDiscovery: {
            title: '24/7 product discovery',
            body: 'Our powerful bot scans 1.2 million products every day, working around the clock to find the best deals and freebies available.'
          }
        },
        everything: 'Everything you need',
        everythingBody:
          'With our advanced technology, securing incredible discounts online has never been easier. Simply set your discount parameters and let our software handle the rest, even purchasing the discounted items for you.',
        customersSaying: 'What our customers are saying',
        or: 'or',
        whatsIncluded: 'What’s included',
        faq: {
          question1: {
            question:
              'How is FreebieFlow able to send so many packages to my house for free?',
            answer:
              'Our software scrapes the entire internet for coupon codes that stack to make the product have a final cost of zero dollars. These coupon codes often come from sellers who are discounting their product to zero in order to increase their sales ranking or make room for new stock.'
          },
          question2: {
            question:
              'Why do I need to pair the software with my Amazon account?',
            answer:
              'We pair with your Amazon account to send items that are shipped directly from Amazon to ensure fast and free shipping. If you choose to check out heavily discounted items, this also allows you to have free returns. Rest assured, your data security is our #1 priority and your data is encrypted and never shared.'
          },
          question3: {
            question:
              'What kind of items does FreebieFlow send, and how often?',
            answer:
              'FreebieFlow is known for sending a diverse variety of products - keeping the unboxing experience a thrill! Some items we’ve sent in the past include drones, robot vacuums, smart watches, and much more. Although how often we send the items is random, you can expect around 10 to 30 items a month!'
          },
          question4: {
            question: 'How does payment work?',
            answer:
              'You only ever pay our monthly subscription cost. We will never have any hidden fees. No matter how many free items we send you, it will always be the same price!'
          },
          question5: {
            question: 'What if I want to cancel or get a refund?',
            answer:
              'You can cancel your subscription at any time. Once you cancel, you will not be charged for the next month and your subscription will end at the end of your billing cycle.'
          },
          question6: {
            question: 'Does FreebieFlow work in any country?',
            answer:
              'FreebieFlow requires an address in the USA in order to ship free items.'
          }
        },
        cantFind: `Can't find the answer you're looking for? Reach out to our `,
        customerSupport: 'customer support',
        team: 'team.',
        readyToDiveIn: 'Ready to dive in?',
        readyToReceive: 'Start receiving freebies today.',
        couponBanner: {
          title:
            'Welcome to FreebieFlow! Get $10 off your first month using code',
          code: 'SPRING10',
          atCheckout: 'at checkout!'
        },
        claimNow: 'Claim Now'
      },
      navBar: {
        dashboard: 'Dashboard',
        settings: 'Settings',
        telegram: 'Join us on Telegram',
        warehouse: 'Warehouse',
        refer: 'Refer a friend - get $10!',
        features: 'Features',
        how_it_works: 'How It Works',
        latest_deals: 'Latest Deals',
        testimonials: 'Testimonials',
        faq: 'FAQ',
        pricing: 'Pricing',
        signIn: 'Sign In'
      },
      dashboard: {
        cancelsOn: 'Cancels on',
        currentPlan: 'Current plan',
        setCancel: 'set to cancel on',
        renewsOn: 'renews on',
        figureItOutMyself: "I'll figure it out myself",
        niceJob: '🙂 Nice job!',
        savedTotal: [
          'In total, you have saved',
          ' and received',
          [
            `This month alone you've made back your monthly subscription`,
            'times over!'
          ]
        ],
        chartData: {
          pastWeek: 'Past Week',
          monthly: 'Monthly',
          overTime: 'Your savings over time',
          moneySaved: 'Money Saved'
        },
        canceledDisclaimer:
          'Note: these stats only take into account delivered packages.',
        noHits: 'No hits yet. Check back later!',
        recentHits: 'Your recent checkouts',
        filterValues: ['All', 'Arriving', 'Expected', 'Pending', 'Delivered'],
        search: 'Search...',
        yourAccount: 'Your account',
        buyMore: 'Buy more account slots.',
        addFreebie: 'Click to add a freebie account!',
        addDeal: 'Click to add a deal account!',
        accountActions: {
          start: 'Start',
          pause: 'Pause',
          edit: 'Edit',
          delete: 'Delete',
          pauseConfirm: 'Are you sure you want to pause this account?',
          pauseConfirmMessage:
            'Keep in mind pausing an account only pauses it from checking out freebies. Your subscription will still be active and charged. Visit the settings page for billing related inquiries.',
          pauseConfirmButton: 'Pause the Amazon Account'
        },
        active: 'FreebieFlow is active. Your settings allow only',
        active2:
          'FreebieFlow is active. Your settings allow all freebies and deals with at least a',
        loggingIn: [
          'Logging in... This usually takes up to 2 minutes. The page will automatically refresh when done.',
          'Logging in...'
        ],
        contactSupport: 'Contact Support For Help',
        toastMsgs: {
          active: 'Your account is now active.',
          inactive: 'Your account is now inactive.'
        },
        pressStart: 'Press start to secure your freebies!',
        discount: 'discount',
        maxPriceOf: 'max price of',
        and: 'and',
        searching: `Searching for deals...`,
        askSupport: 'Ask support',
        errorConnectingYourAccount: 'Error connecting your Amazon account',
        continueSetup: 'Continue setup',
        amazonPrimeWarning: {
          message: 'No Prime - You may be missing out!',
          whatsThis: "What's this?",
          modal: {
            title: 'Warning: You may be missing out!',
            description:
              'We’ve detected that your account doesn’t have Amazon Prime. In order to get the most out of FreebieFlow, your account should have Amazon Prime to get their free shipping.',
            buttons: {
              ok: 'Acknowledge',
              contactSupport: 'Contact Support'
            }
          }
        }
      },
      settings: {
        freebieAccts: 'Freebie Account(s)',
        dealAccts: 'Deal Account(s)',
        tgAccess: 'Telegram Community Access',
        somethingWentWrong: 'Something went wrong!',
        successPromo: 'Successfully created promocode!',
        copyPopup: 'Copied to clipboard!',
        enterPromo: 'Enter Promocode',
        customDesc:
          'Create a custom promocode code to offer your friend $10 off their first month!',
        customPromocode: 'Custom Promocode',
        create: 'Create',
        updateAcctInfo: 'Update account information',
        updateSub:
          'View or update information about your FreebieFlow account such as your email and password.',
        currentPassword: 'Current password',
        changeEmail: 'Change email address',
        newPassword: 'New password',
        enterNewPassword: 'Enter new password',
        confirmPassword: 'Confirm password',
        updateInfo: 'Update Info',
        lengthWarning: 'Password must be at least 8 characters long.',
        upperWarning: 'Use at least one uppercase letter and one number.',
        referralProgram: 'Referral Program',
        referralProgramInfo:
          'Enroll in our referral program by providing us with your PayPal email!',
        visitorCount: 'Visitor Count',
        peopleVisited: 'People who have visited your referral link.',
        purchases: 'Purchases',
        peoplePaid:
          'People who have paid for a subscription using your referral link.',
        shareLink:
          'Share your personalized Referral Link and earn $10 per paying user!',
        copy: 'Copy',
        copyYourReferral: 'Copy your referral link',
        yourCouponCode: 'Your coupon code',
        enroll: 'Enroll',
        paypalEmail: 'PayPal Email',
        paypalInfo:
          'This is the PayPal email that you would like to receive payments to.',
        totalEarnings: 'Total Earnings',
        totalEarningsInfo:
          'This is the total amount of money you have earned from your referrals.',
        backToDashboard: 'Back to Dashboard',
        update: 'Update',
        yourPlan: 'Your Plan',
        viewCurrPlan:
          'View your current plan or update your plan to get access to more features.',
        plans: {
          Basic: 'Basic',
          Pro: 'Pro',
          Family: 'Family'
        },
        showMore: 'Show More',
        updatePlan: 'Update Plan',
        manageBilling: 'Manage Billing',
        cancelSub: 'Cancel Subscription',
        uncancelSub: 'Uncancel Subscription',
        logout: 'Log out',
        referralProgramUi: {
          getPaidCash10: 'Get Paid $10 Cash',
          perPersonYourRefer: 'Per Person You Refer',
          validEmail: 'Please enter a valid email address.',
          description:
            'No limits on how many people you can refer! Get your own custom coupon code that you can share to give your friends/followers a $10 off discount!'
        }
      },
      register: {
        reg: 'Register',
        regAccount: 'Register for an account',
        regBody:
          'Getting started is easy! Simply register an account, subscribe, link your Amazon Prime, and start getting free products right away.',
        firstName: 'First Name',
        lastName: 'Last Name',
        emailAddress: 'Email address',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        iAgree: 'I agree to the',
        terms: 'Terms of Service',
        acknowledgeLang: 'I acknowledge that I must have a USA address',
        info: 'Info',
        linkAcctNow: 'Do I need to link my account now?',
        linkBody:
          'Once you register and subscribe, you will be able to easily link your Amazon account to your FreebieFlow account in your user dashboard.',
        payReq: 'When is payment required?',
        payReqBody:
          'After you register, you will be able to add your payment information and subscribe to a plan. You will be able to use FreebieFlow immediately after subscribing.',
        did: 'Did you know?',
        onAvg: 'On average, our members receive their first freebie',
        withinWeek: 'within a single week ',
        afterSignup: 'after signup.',
        checkEmail: 'Check your email',
        clickVerify: 'Click the verification link in the email we sent to',
        clickVerify2:
          'to continue. If you do not see an email, make sure to check your promotional and spam folders.',
        resend: 'Resend',
        emailSent: 'Email sent',
        youHave: 'You have',
        waitingForYou: 'waiting for you!'
      },
      signIn: {
        success: 'Signed in successfully',
        typeEmail: 'Type your email to send a password reset',
        forgotPassword: 'Forgot your password',
        email: 'Email...',
        whatIsEmail: 'What is your email?',
        submit: 'Submit',
        signIn: 'Sign In',
        signInto: 'Sign in to your account',
        forgotPassword: 'Forgot your password?'
      },
      plans: {
        subNow: 'Subscribe now to start getting',
        freeStuff: 'free stuff delivered right to your door',
        downGrade: 'You can upgrade or downgrade at any time.',
        easyAI:
          'Our easy-to-use AI software scans millions of products for freebies and massive discounts, delivering them to your door with free shipping.',
        subTo: 'Subscribe to',
        features: [
          'Amazon Prime Integration',
          '24/7 Support',
          'Money-back Guarantee'
        ]
      },
      reviews: [
        {
          body: 'I have been using freebie flow for less than a month now and I love it. I didn’t expect to get as many packages as I did. The amount of money that I saved is amazing and will continue to using freebie flow.',
          name: 'Liz M.',
          details: 'FreebieFlow Customer'
        },
        {
          body: "I love Freebie Flow! Been here since April and no regrets. I'm able to get some sleep while FF works for me. Is like my personal assistant. I've saved so much money. My family loves FF too, they're getting freebies too! Last but not least, customer service is AMAZING!",
          name: 'Yaishirette P.',
          details: 'FreebieFlow Customer'
        },
        {
          body: "I approached Freebie Flow with skepticism, but after just a month, I found myself reveling in over 70 fantastic free and discounted packages!!!!! It's been an absolute thrill to receive these surprise packages, each one adding a spark of excitement to my day.",
          name: 'Brittany Kalinowski',
          details: 'FreebieFlow Customer'
        },
        {
          body: 'I’m a new subscriber and am already happy with my experience so far. 3 products have been ordered for me already having had the account a day or 2. And customer service has been really helpful and responsive as well',
          name: 'Randi T.',
          details: 'FreebieFlow Customer'
        },
        {
          body: 'I must say I was a bit hesitant at first. I thought this is just too good to be true. But once I gave it a try with the help of the amazing customer service team this past September. I fell in love with all the surprises I get weekly. I am hooked!',
          name: 'Deb B.',
          details: 'FreebieFlow Customer'
        },
        {
          body: "I have been doing FreebieFlow for about a month but already received way more than what covers the cost of the subscription. Their customer service is also really responsive. It's really fun to get surprises coming my way every week!",
          name: 'Melinda K.',
          details: 'FreebieFlow Customer'
        },
        {
          body: "I was extremely hesitant but had a moment where I just decided to try it out and ended up scoring my first freebie the next day! I'm now 4 days in and have 4 freebies scored valued at over $120! If you're hesitant, just try it! I'm loving this and so glad I jumped in!",
          name: 'Mermie E.',
          details: 'FreebieFlow Customer'
        },
        {
          body: 'I have only been trying freebie flow for a week and so far so good! I’ve been getting a decent amount of items! Wish I would have found out about it sooner. It’s fun and exciting to see what new packages you get to open everyday! 100% worth it! They have excellent service and a great website and telegram set up! Highly recommend!',
          name: 'Rachel B.',
          details: 'FreebieFlow User'
        },
        {
          body: "This is a pretty cool app. You can select how much you would like to spend or save. It's full of surprises and I like that. Thinking about upgrading just for fun! Try it for the first month.",
          name: 'Kitty L.',
          details: 'FreebieFlow User'
        },
        {
          body: "This website is so easy and Exciting. All you do is pay for the month at a reasonable price, then check everyday to see what hit. This is something everybody needs to try, it's Great!",
          name: 'Megan S.',
          details: 'FreebieFlow Fan'
        },
        {
          body: 'No need to wait around for amazing Deals with Freebies Flow; they’re easy to access and make incredible savings possible!',
          name: 'Shannon W.',
          details: 'Frequent Freebie User'
        }
      ],
      editing: {
        confirm: 'Yes, delete it!',
        delete: 'Delete account',
        warning: 'This will permanently delete your account.',
        saveChanges: 'Save changes',
        editAcct: 'Edit account settings',
        youreEditing: 'You are editing the settings for',
        passwordTip: 'This is the account password.',
        discPercent: 'Discount Percentage',
        discTip:
          "This is the minimum discount percentage you'd like the bot to checkout.",
        maxPrice: 'Max Price',
        maxTip: 'This is the most you will spend on a discounted item.',
        minPrice: 'Min Price',
        minTip:
          'This is the minimum price of the item before discount the bot will search for.',
        advanced: 'Advanced settings',
        advSettings: {
          instructions: 'Instructions',
          secret: '2FA Secret',
          secretTip: 'This is the account 2FA secret.',
          blacklist: 'Blacklist',
          blacklistTip: 'This is your filter list.',
          blackPlaceholder: 'Enter Keyword or ASIN, comma separated'
        }
      },
      guide: {
        back: 'Back',
        provOtp: 'Provide OTP code',
        prov2fa: 'Provide 2FA code',
        setAuth: 'Setup 2FA',
        allDone: 'All done!',
        enterCont: 'Enter the code here to continue.',
        addCode2: 'Press "add code".',
        keyBox: 'In the key box paste this secret key.',
        acctBox: 'In the account box type "Amazon".',
        setup: 'Select "enter a setup key".',
        openApp: 'Open the app press the ' + ' icon to create a new code.',
        googleAuth: '"Google Authenticator" app on your phone.',
        getThe: 'Get the',
        free: 'FREE',
        goToDash: 'Go to dashboard',
        done: "You're all set up and ready to go!",
        scanQr: 'Scan this QR code with your TOTP app.',
        submit: 'Submit',
        enterCode: 'Enter the code here to continue.',
        signingIn: 'Signing in...',
        twoMins: 'This may take up to 2 minutes, please be patient!',
        username: 'Amazon Email',
        password: 'Amazon Password',
        pleaseEnter: 'Please enter your email and password.',
        encrypted: 'All of your information is encrypted and stored securely!',
        willUse:
          'FreebieFlow will use your Amazon email and password to automatically link your account.',
        tenMins: 'These steps should take about 10 minutes.',
        letsLink:
          "Let's link your Amazon account so we can start sending you free stuff!",
        signIn: 'Sign in to Amazon',
        welcome: 'Welcome to FreebieFlow!',
        scanQR: 'Select "Scan a QR code"',
        scanQRPage: 'Scan the QR on your Amazon page',
        next: 'Next',
        getMyKey: 'Get my key',
        amznEmail: 'Amazon Email',
        amznPass: 'Amazon Password',
        secret2FA:
          '2FA Secret Key (looks like this: "M8LY SULH LAT2 GAG3 6BN3 NO0Q 4MNO WHY9 O4MM ANLV 75GE GZKR O5GA")',
        otp: 'OTP (6 digit code from your authenticator app)',
        paste2FA: 'Paste your 2FA Secret Key from earlier...',
        startLoginProcess: 'Start Login Process',
        description:
          'Freebie Flow requires your Amazon account email and password so that it can check out items on your behalf. All account information is encrypted and securely stored in the cloud.',
        signInAmzn: 'Sign in to Amazon',
        cooldown:
          'Your authenticator app will refresh 6 digit codes every 30 seconds.',
        list: [
          'You will now see a 6 digit code generated in your authenticator app that changes every 30 seconds',
          'Enter 6 digit code into the box on Amazon',
          'Select “Verify OTP and continue”',
          'Click checkbox “don’t require OTP”',
          'Select “Got it. Turn on Two-Step Verification”'
        ],
        complete2Step: 'Complete 2-step setup',
        note: "Note: you do not have to pay for Google Authenticator–it's a free app.",
        addCode: 'Press add code',
        boxPaste: 'In the key box paste 2FA Secret Key',
        acctBox: 'In the account box type “Amazon”',
        setupKey: 'Select “enter a setup key”',
        addIcon: 'Click the “+” icon to add a code',
        getThe: 'Get the',
        freeGoogle: 'FREE “Google Authenticator” app',
        onYourPhone: 'on your phone',
        downGoogle: 'Download Google Authenticator',
        save2FA: 'Your 2FA Secret Key is important! Save it for later.',
        paste2FAAlert: 'Paste your 2FA Secret Key in a safe place!',
        copy2FA: 'Copy your 2FA Secret Key',
        cantBarcode: "Click 'Can't Scan The Barcode?'",
        authSelect: 'Select the Authenticator app option',
        yellowBtn: 'Click the yellow "Get Started" button',
        setup2FA: 'Set up 2FA',
        clickDisable: 'Click disable',
        checkbox: 'Check the checkbox',
        disableBtn: 'Click the disable button',
        thisLink: 'this link',
        goTo: 'Go to',
        ensureDisabled: 'Ensure 2-factor authentication is disabled',
        videoTutorial: 'Video Tutorial',
        exampleKey: 'EXAMPLE 2FA SECRET KEY',
        acctLng: 'Account language must be set to',
        balance: 'balance',
        gcCredit: 'Gift Card/Credit',
        letsGetStarted: 'Let’s get started!',
        processTen:
          'This process takes about 10 minutes, but if you follow the instructions correctly you’ll be receiving boxes very soon!',
        amznTwo: 'Amazon 2FA Secret Keys look like this:',
        amzAcctNeeds: 'Your Amazon Account needs a',
        defaultShip: 'default shipping address and payment method set',
        acctIs: 'Account is',
        not: 'NOT',
        a: 'a',
        english: 'English or Spanish',
        does: 'Does',
        ikMyKey: 'I know my 2FA Secret Key already',
        haveA: 'have a',
        bizAcct: 'business account',
        welcome: 'Welcome to FreebieFlow!',
        welcomeBody:
          "We're excited to have you in the community! Before we begin, we'll need to sync your account so that you can receive packages.",
        plsConfirm: 'First, please confirm the following:',
        changeLangToastSuccess: 'Language preference successfully updated!',
        amazonHasBeenEnabled: 'Your Amazon account has been enabled',
        twoFaHasBeenEnabled: '2FA has been enabled',
        youAreReady: 'You are ready to go!',
        showVideo: 'Watch the video to see how to set up authenticator',
        hideVideo: 'Hide Video'
      },
      error: {
        invalidCredentials:
          'Your Amazon account credentials are invalid. Please try again. [C]',
        twoFaInvalidCredentials:
          'Your Amazon account credentials are invalid. Please try again. [C]',
        invalidPassword:
          'Your Amazon account password is invalid. Please delete and re-add the account with the correct password. [P]',
        twoFaInvalidPassword:
          'Your Amazon account password is invalid. Please delete and re-add the account with the correct password. [P]',
        invalidEmail:
          'Your Amazon account email/username is invalid. Please try again. [E]',
        twoFaInvalidEmail:
          'Your Amazon account email/username is invalid. Please try again. [E]',
        invalidMfa:
          'Your Amazon account 2FA secret is invalid! Please follow the video guide. Contact support if issue persists. [E]',
        sessionError:
          'An error occurred while logging in. Contact support if issue persists after trying again. [S]',
        generalError:
          'An error occurred while logging in. Contact support if issue persists after trying again. [G]',
        accountOnHold:
          'Your Amazon Account is on Hold. Please resolve this by logging in manually [H]',
        accountLocked:
          'Your Amazon Account is Locked. Please resolve this by logging in manually[H]',
        manualMfa:
          'Your 2FA has not been fully configured. Please watch the tutorial video in our help section. You might not have fully enabled 2FA on the Amazon website. [M]',
        requiresPasswordReset:
          'Your Amazon Account requires a password reset. Please resolve this by logging in manually [R]',
        invalidOtpSetup:
          'Your 6 digit code is incorrect. Contact support if you need help.',
        errorValidatingOtp: 'Error while validating OTP',
        invalidOtp:
          'Invalid OTP code. Please readd account and make sure to provide the correct 2FA code. [O]',
        twoFaInvalidOtp:
          'Invalid OTP code. Please readd account and make sure to provide the correct 2FA code. [O]',
        noPrime:
          'Your Amazon account does not have Amazon Prime. Please update and re-sync the account. [N]',
        notUsa:
          'Your Amazon account is not a USA account. Please update and re-sync the account. [U]',
        notUsaAddress:
          'Your Amazon account does not have a USA shipping address. Please update and re-sync the account. [A]',
        noDefaultAddress:
          'Your Amazon account does not have a default shipping address. Please update and re-sync the account. [D]',
        noDefaultPaymentMethod:
          'Your Amazon account does not have a default payment method. Please update and re-sync the account. [M]',
        notEnglish:
          'Your Amazon account does not have English or Spanish set as the language. Please update and re-sync the account. [L]',
        noDefaultOneClick:
          'Your Amazon account does not have a default shipping address or default payment method set. Please update and re-sync the account.',
        businessAccount:
          'We do not support Amazon Business accounts. Please add a regular Amazon Prime account. [B]',
        errdesc: 'Error logging in:',
        twoFaOld2faRequested: 'You did not complete the setup for the account.',
        twoFaMfaRequested: 'You did not complete the setup for the account.'
      }
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  debug: true,
  useSuspense: false
});

export default i18n;
