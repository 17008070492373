import React, { Fragment } from 'react';

import { GrClose } from 'react-icons/gr';
import { Dialog, Transition } from '@headlessui/react';

const TermsOfServiceModal = ({
  showTermsOfServiceModal,
  setShowTermsOfServiceModal
}) => {
  return (
    <div>
      <Transition.Root show={Boolean(showTermsOfServiceModal)} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowTermsOfServiceModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full transform overflow-hidden rounded-md bg-white px-7 py-9 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-9">
                  <div className="">
                    <button
                      onClick={() => {
                        setShowTermsOfServiceModal(false);
                      }}
                      className="absolute top-3 right-3"
                    >
                      <div className="rounded-full bg-gray-50 hover:bg-gray-100 duration-150 p-2">
                        <GrClose className="text-gray-800 text-base smtext-lg" />
                      </div>
                    </button>
                    <div>
                      <div className="prose">
                        <h1 className="-mb-6 text-3xl sm:text-4xl">
                          FreebieFlow, LLC User Agreement/Terms of Service
                        </h1>
                        <section>
                          <h2>1. General Terms</h2>
                          <p>
                            This User Agreement/Terms of Service, the Privacy
                            Policy, and all policies and additional terms posted
                            on and in our sites, applications, tools, and
                            services (collectively "Services") set out the terms
                            on which FreebieFlow, LLC (“Freebie Flow”) offers
                            you access to and use of our Services. By accessing
                            and placing an order with Freebie Flow, you confirm
                            that you are in agreement with and bound by the User
                            Agreement/Terms of Service outlined below. These
                            terms apply to the entire website and any email or
                            other type of communication between you and Freebie
                            Flow. You agree to comply with all terms of this
                            User Agreement/Terms of Service when accessing or
                            using our Services.
                          </p>
                          <p className="font-bold">
                            Please be advised that this User Agreement/Terms of
                            Service contains provisions that govern how claims
                            you and we have against each other are resolved (see
                            "Disclaimer of Warranties; Limitation of Liability"
                            and "Legal Disputes" provisions below). It also
                            contains an Agreement to Arbitrate which will, with
                            limited exception, require you to submit claims you
                            have against us or our agents to binding and final
                            arbitration, unless you opt out of the Agreement to
                            Arbitrate (see Dispute Resolution). If you do not
                            opt out: (1) you will only be permitted to pursue
                            claims against us or our agents on an individual
                            basis, not as a plaintiff or class member in any
                            class or representative action or proceeding, and
                            (2) you will only be permitted to seek relief
                            (including monetary, injunctive, and declaratory
                            relief) on an individual basis.
                          </p>
                          <p>
                            Under no circumstances shall Freebie Flow team be
                            liable for any direct, indirect, special, incidental
                            or consequential damages, including, but not limited
                            to, loss of data or profit, arising out of the use,
                            or the inability to use, the materials on this site,
                            even if Freebie Flow team or an authorized
                            representative has been advised of the possibility
                            of such damages. If your use of materials from this
                            site results in the need for servicing, repair or
                            correction of equipment or data, you assume any
                            costs thereof. Freebie Flow will not be responsible
                            for any outcome that may occur during the course of
                            usage of our resources. We reserve the rights to
                            change prices and revise the resources usage policy
                            in any moment.
                          </p>
                        </section>
                        <section>
                          <h2>2. License</h2>
                          <p>
                            Freebie Flow grants you a revocable, non-exclusive,
                            non-transferable, limited license to download,
                            install and use the website strictly in accordance
                            with the terms of this User Agreement/Terms of
                            Service. This User Agreement/Terms of Service is a
                            contract between you and Freebie Flow (referred to
                            in this User Agreement/Terms of Service as "Freebie
                            Flow", "us", "we" or "our"), the provider of the
                            Freebie Flow website and Freebie Flow Service
                            accessible from the Freebie Flow website (which are
                            collectively referred to in this User
                            Agreement/Terms of Service as the "Freebie Flow
                            Service"). You are agreeing to be bound by this User
                            Agreement/Terms of Service. If you do not agree to
                            this User Agreement/Terms of Service, please do not
                            use the Freebie Flow Service. In this User
                            Agreement/Terms of Service, "you" refers both to you
                            as an individual and to any entity you represent. If
                            you violate any of this User Agreement/Terms of
                            Service, we reserve the right to cancel your account
                            or block access to your account without notice.
                          </p>
                        </section>
                        <section>
                          <h2>3. Definitions</h2>
                          <p>
                            To help explain things as clearly as possible in
                            this User Agreement/Terms of Service, every time any
                            of these terms are referenced, are strictly defined
                            as:
                          </p>
                          <ul>
                            <li>
                              <span className="italic">Cookie:</span> small
                              amount of data generated by a website and saved by
                              your web browser. It is used to identify your
                              browser, provide analytics, remember information
                              about you such as your language preference or
                              login information.
                            </li>
                            <li>
                              <span className="italic">Company:</span> when this
                              policy mentions “Freebie Flow,” “Company,” “we,”
                              “us,” or “our,” it refers to FreebieFlow, LLC,
                              2717 Commercial Center Boulevard Cinco Ranch,
                              Suite E200, Katy, TX 77494.
                            </li>
                            <li>
                              <span className="italic">Country:</span> where
                              Freebie Flow or the owners/founders of Freebie
                              Flow are based, in this case is the United States.
                            </li>
                            <li>
                              <span className="italic">Device:</span> any
                              internet connected device such as a phone, tablet,
                              computer or any other device that can be used to
                              visit Freebie Flow and use Freebie Flow Service.
                            </li>
                            <li>
                              <span className="italic">
                                Freebie Flow Service:
                              </span>{' '}
                              refers to Freebie Flow Service provided by Freebie
                              Flow as described herein.
                            </li>
                            <li>
                              <span className="italic">
                                Third-party service:
                              </span>{' '}
                              refers to advertisers, contest sponsors,
                              promotional and marketing partners, and others who
                              provide our content or whose products or services
                              we think may interest you.
                            </li>
                            <li>
                              <span className="italic">Website:</span> Freebie
                              Flow’s website, which can be accessed via this
                              URL: https://freebieflow.com/
                            </li>
                            <li>
                              <span className="italic">You:</span> a person or
                              entity that is registered with Freebie Flow to use
                              Freebie Flow Service. “Entity” includes any
                              organization listed in the Texas Business
                              Organizations Code.
                            </li>
                            <li>
                              <span className="italic">Parties:</span> Includes
                              Freebie Flow and You and any subsidiaries,
                              affiliates, and all of our respective officers,
                              agents, partners, and employees of either Freebie
                              Flow or You.
                            </li>
                          </ul>
                        </section>
                        <section>
                          <h2>
                            4. User Registration and Subscription Services
                          </h2>
                          <p>
                            You will be required to register and/or set up an
                            account/profile to access, visit and/or use Freebie
                            Flow Service or the Website, in which case you may
                            be provided, or required to choose, a password
                            and/or User ID. You may provide a credit, debit, or
                            payment account number, or other payment
                            information, as well as your name, telephone
                            number(s), email and/or street address. Other
                            personal information such as your age, gender, and
                            preferences may also be requested. In addition, you
                            may be asked to provide us similar information by
                            telephone, postal mail, social media interaction or
                            messaging (e.g., email, SMS, MMS, or similar
                            technologies). All of the foregoing information
                            shall be referred to as your “Registration
                            Information”. We may use and share your Registration
                            Information as described in our Privacy Notice. You
                            agree, represent, warrant, covenant and guarantee
                            that all Registration Information provided by you is
                            true, accurate, complete, up-to-date, and solely
                            yours. You may not impersonate, imitate or pretend
                            to be somebody else when registering and/or setting
                            up an account/profile on Services. If any of your
                            Registration Information changes, you are
                            responsible for updating it promptly by using the
                            mechanism or contact information on Services that
                            allows you to change or update your Registration
                            Information, if available. If no such mechanism or
                            contact information is available on Services, please
                            notify us in accordance with our Privacy Notice
                            Coordinator as described in our Privacy Notice.
                          </p>
                          <p className="font-bold">
                            WE SHALL HAVE NO LIABILITY ASSOCIATED WITH OR
                            ARISING FROM YOUR FAILURE TO MAINTAIN ACCURATE,
                            COMPLETE OR UP-TO-DATE REGISTRATION INFORMATION,
                            INCLUDING WITHOUT LIMITATION YOUR FAILURE TO RECEIVE
                            CRITICAL INFORMATION OR ORDERED PRODUCTS. WE SHALL
                            NOT BE RESPONSIBLE FOR VERIFYING YOUR REGISTRATION
                            INFORMATION.
                          </p>
                          <p>
                            You may not authorize or permit anyone else to
                            access and/or use your Registration Information, or
                            access, visit and/or use Services by use of your
                            account/profile and/or Registration Information. You
                            may not access and/or use anyone else's Registration
                            Information, or access, visit and/or use Services by
                            use of anyone else's account/profile and/or
                            Registration Information. You may not sub-license,
                            transfer, sell, rent or assign your Registration
                            Information to any third party without our written
                            approval. You agree to keep your password
                            confidential and will be responsible for all use of
                            your account and password. We reserve the right at
                            any time to remove, reclaim, or change a username or
                            avatar you select if we determine, in our sole
                            discretion, that such username or avatar is
                            inappropriate, obscene, or otherwise objectionable.
                            You are responsible for maintaining the
                            confidentiality of your password to access Services,
                            and you are solely responsible for all activities
                            that occur under your password. You agree to
                            immediately change your password and notify us here
                            if you suspect or become aware of any unauthorized
                            use of your password or any other breach of security
                            related to Services. We reserve the right to require
                            you to alter your password if we believe that your
                            password is no longer secure. You agree that we will
                            not be liable for any loss or damage arising from
                            your failure to adequately safeguard your password
                            or someone else’s use of your account.
                          </p>
                        </section>
                        <section>
                          <h2>4.1 Subscription Services</h2>
                          <p>
                            Freebie Flow Service may be available only through
                            creation of a subscription account and payment of a
                            fee (“Subscription Services”), which may be
                            accomplished through a third-party service provider.
                            Through such Subscription Services accounts, you
                            will have access to the Subscription Services. You
                            are not authorized to access any Subscription
                            Services unless you have opened a subscription
                            account and paid the appropriate fee, have received
                            access credentials (e.g., a username and password)
                            from us, and are using those access credentials. You
                            may not assist anyone else in accessing Subscription
                            Services on an unauthorized basis, including by
                            sharing your access credentials or providing any
                            content or other materials that you obtained through
                            Subscription Services to third parties. You are
                            responsible for maintaining the confidentiality of
                            your access credentials and for all usage or
                            activity on your Subscription Services accounts,
                            including the use of Subscription Services by any
                            third party authorized by you to use your access
                            credentials. Such responsibility expressly includes
                            any purchases made or other charges incurred on your
                            credit card in connection with your use (or an
                            authorized third party’s use) of the Subscription
                            Services. In the event of any fraudulent, abusive or
                            otherwise illegal activity on your Subscription
                            Services accounts, we may, in our sole discretion,
                            terminate those accounts and refer you to
                            appropriate law enforcement agencies. You may be
                            responsible for damages from any such fraudulent,
                            abusive, or otherwise illegal activity. By
                            subscribing to Subscription Services you confirm
                            that you are at least eighteen (18) years of age,
                            that all information you submit is true and correct
                            (including all credit card information), and that
                            you are the authorized holder of the credit card.
                            You agree to pay all subscription fees and other
                            charges to your Subscription Services accounts,
                            including any applicable taxes. Company and/or the
                            particular service provider utilized by Company or
                            otherwise incorporated into the Subscription Service
                            reserves the right to change the amount of, or the
                            basis for determining, any subscription fees or
                            other charges for the Subscription Services and to
                            institute new subscription fees or other charges. If
                            fees cannot be charged to the payment method you
                            designate, or payment is returned to us for any
                            reason, including charge back, we reserve the right,
                            in addition to our other remedies, to: (i) demand
                            immediate payment of all outstanding fees due to us
                            from you; (ii) assess an additional 1.5 percent late
                            charge, or the highest amount allowed by law,
                            whichever is lower; (iii) take any and all lawful
                            steps necessary to collect fees owed to us, and you
                            will be responsible for all costs and expenses
                            incurred in connection with such collection
                            activity, including collection fees, court costs,
                            and attorneys' fees; (iv) charge such fees to any
                            other payment method you have on file with us and/or
                            our third-party service providers; and (v)
                            terminate, discontinue, suspend, and/or restrict
                            your account/profile, your ability to access, visit
                            and/or use Services or any portion thereof, and/or
                            the User Agreement/Terms of Service, including
                            without limitation any of our purported obligations
                            hereunder. Some Subscription Services are accessible
                            only using a particular kind of device. You should
                            investigate Subscription Services before you pay for
                            access to them to ensure that they will work with
                            your device because we will not refund any fees you
                            paid if the Subscription Services to which you
                            subscribe are not compatible. Also, certain
                            Subscription Services may not be available for use
                            in every jurisdiction, and we will not refund any
                            fees you paid if the Subscription Services to which
                            you subscribe are not available. For information on
                            compatibility and availability, please visit the
                            applicable provider’s website. You are responsible
                            for obtaining and maintaining at your own expense
                            all equipment, hardware, software, and telephone,
                            cable, mobile, wireless, Internet and other services
                            necessary to access, visit and/or use Services. If
                            you are accessing Services via a mobile device, your
                            mobile carrier may charge you fees for data, text
                            messaging, and other mobile access or communications
                            services. We may suspend or terminate your access to
                            Subscription Services at any time without notice to
                            you. If we do so, you will not be responsible for
                            fees associated with the Subscription Services after
                            the termination becomes effective, but you will not
                            have any other remedies against us, and we will not
                            issue any refunds. If you have breached or violated
                            any obligation under these Terms, you will not be
                            entitled to any remedy. To cancel your subscription
                            to Subscription Services, you must follow the
                            procedures described by Company or on the applicable
                            provider’s website. If we or the applicable provider
                            does not list cancellation procedures for a
                            particular Subscription Service, you must contact us
                            at support@freebieflow.com We do not refund or
                            prorate Subscription Services, or any other
                            purchases made through Services, for any reason
                            other than the 30 day refund policy below. Requests
                            to terminate subscriptions will be effective once
                            the billing period in which we received the
                            cancellation concludes.
                          </p>
                          <h2>4.2 30 Day Guarantee Refund Policy</h2>
                          <p>
                            Notwithstanding the above. If you do not
                            successfully get a free or reduced cost product in
                            your first month of using Freebie Flow Services, we
                            offer a money back guarantee. To request a refund
                            under this policy please contact
                            support@freebieflow.com within 7 days of the first
                            month of your subscription. Any refund not requested
                            within this 7 day window will only be granted with
                            manager approval. Any request for a refund within 60
                            days of use under this policy will be rejected.
                          </p>
                          <h2>4.3 Non-Subscription Services</h2>
                          <p>
                            You may be asked to register for certain activities
                            in connection with Services other than Subscription
                            Services. When you register, you agree to provide
                            accurate, current and complete information about
                            yourself as requested or directed and to promptly
                            update this information to maintain its accuracy.
                            Company has the right to suspend or terminate any
                            account or other registration and to refuse any and
                            all current or future use if it suspects that such
                            information is inaccurate or incomplete. You are
                            responsible for maintaining the confidentiality of
                            any password and username that you are given or
                            select, and you are responsible for all activities
                            that occur under your password or account.
                          </p>
                        </section>
                        <section>
                          <h2>5. Restrictions</h2>
                          <p>
                            You agree not to, and you will not permit others to:
                          </p>
                          <ol>
                            <li>
                              License, sell, rent, lease, assign, distribute,
                              transmit, host, outsource, disclose or otherwise
                              commercially exploit the website or make the
                              platform available to any third party aside from
                              those expressly permitted by this software for
                              example Amazon.
                            </li>
                            <li>
                              Modify, make derivative works of, disassemble,
                              decrypt, reverse compile or reverse engineer any
                              part of the website.
                            </li>
                            <li>
                              Remove, alter or obscure any proprietary notice
                              (including any notice of copyright or trademark)
                              of Freebie Flow or its affiliates, partners,
                              suppliers or the licensors of the website.
                            </li>
                          </ol>
                        </section>
                        <section>
                          <h2>6. Returns and Refunds on Goods</h2>
                          <p>
                            Freebie Flow does not handle shipping directly and
                            therefore is not responsible for any returns or
                            refunds. Please check with Amazon or any related
                            third party Amazon seller/reseller for their
                            return/refund policy. Freebie Flow cannot assist
                            with refunds/returns on goods purchased.
                          </p>
                        </section>
                        <section>
                          <h2>7. Your Suggestions</h2>
                          <p>
                            Any feedback, comments, ideas, improvements or
                            suggestions (collectively, "Suggestions") provided
                            by you to Freebie Flow with respect to the website
                            shall remain the sole and exclusive property of
                            Freebie Flow. Freebie Flow shall be free to use,
                            copy, modify, publish, or redistribute the
                            Suggestions for any purpose and in any way without
                            any credit or any compensation to you.
                          </p>
                        </section>
                        <section>
                          <h2>8. Links to Other Websites</h2>
                          <p>
                            This User Agreement/Terms of Service applies only to
                            Freebie Flow Service. Freebie Flow’s website may
                            contain links to other websites not operated or
                            controlled by Freebie Flow. Freebie Flow is not
                            responsible for the content, accuracy or opinions
                            expressed in such websites, and such websites are
                            not investigated, monitored or checked for accuracy
                            or completeness by us. Please remember that when you
                            use a link to go from Freebie Flow Service to
                            another website, our User Agreement/Terms of Service
                            are no longer in effect. Your browsing and
                            interaction on any other website, including those
                            that have a link on our platform, is subject to that
                            website’s own rules and policies. Such third parties
                            may use their own cookies or other methods to
                            collect information about you.
                          </p>
                        </section>
                        <section>
                          <h2>9. Cookies</h2>
                          <p>
                            Freebie Flow uses "Cookies" to identify the areas of
                            our website that you have visited. A Cookie is a
                            small piece of data stored on your computer or
                            mobile device by your web browser. We use Cookies to
                            enhance the performance and functionality of our
                            website but are non-essential to their use. However,
                            without these cookies, certain functionality like
                            videos may become unavailable or you would be
                            required to enter your login details every time you
                            visit the website as we would not be able to
                            remember that you had logged in previously. Most web
                            browsers can be set to disable the use of Cookies.
                            However, if you disable Cookies, you may not be able
                            to access functionality on our website correctly or
                            at all. We never place Personally Identifiable
                            Information in Cookies.
                          </p>
                        </section>
                        <section>
                          <h2>10. Account Linking</h2>
                          <p>
                            By signing up for the Freebie Flow service, users
                            authorize the Company to place orders on their
                            behalf on Amazon.com. Users are solely responsible
                            for managing their Amazon.com account and ensuring
                            that they are not charged for any unintended or
                            unwanted orders placed by the Company. The Company
                            is not responsible for any charges or fees incurred
                            by the user on Amazon.com or any other third-party
                            website or service. The Company is not responsible
                            for how users choose to use their Amazon.com account
                            or any consequences that may result from the use of
                            the account in conjunction with the Freebie Flow
                            service. Any actions taken by Amazon against a
                            user’s account, including but not limited to locks,
                            suspensions, holds, or cancellations due to their
                            use of the Freebie Flow service is the sole
                            responsibility of the user to resolve with
                            Amazon.com. Furthermore, the User agrees to
                            indemnify Freebie Flow from any action taken by
                            Amazon as outlined below in Indemnification.
                          </p>
                        </section>
                        <section>
                          <h2>11. Product Guarantees</h2>
                          <p>
                            The items shipped are selected at the discretion of
                            the Company. The Company does not guarantee any
                            specific items or their suitability for any
                            particular purpose. Any issues or concerns regarding
                            the items included should be directed to the
                            Amazon.com seller who sold the item. Users
                            acknowledge that the Company is not responsible for
                            the quality or performance of any items shipped to
                            the user. The Company does not endorse or make any
                            representations or warranties regarding the quality,
                            safety, or efficacy of any items included in the
                            boxes.
                          </p>
                        </section>
                        <section>
                          <h2>
                            12. Changes to our User Agreement/Terms of Service
                          </h2>
                          <p>
                            You acknowledge and agree that Freebie Flow may stop
                            (permanently or temporarily) providing Freebie Flow
                            Service to you or to users generally at Freebie
                            Flow’s sole discretion, without prior notice to you.
                            You may stop using Freebie Flow Service at any time.
                            You must cancel your subscription through the
                            settings page of your online dashboard or by
                            contacting support@freebieflow.com. You do not need
                            to specifically inform Freebie Flow when you stop
                            using Freebie Flow Service, but you are responsible
                            for canceling your subscription. You acknowledge and
                            agree that if Freebie Flow disables access to your
                            account, you may be prevented from accessing Freebie
                            Flow Service, your account details or any files or
                            other materials which is contained in your account.
                            If we decide to change our User Agreement/Terms of
                            Service, we will post those changes on this page or
                            otherwise notify you, and/or update the User
                            Agreement/Terms of Service modification date below.
                          </p>
                        </section>
                        <section>
                          <h2>13. Modifications to Website</h2>
                          <p>
                            Freebie Flow reserves the right to modify, suspend
                            or discontinue, temporarily or permanently, the
                            website or any service to which it connects, with or
                            without notice and without liability to you.
                          </p>
                        </section>
                        <section>
                          <h2>14. Updates to Website</h2>
                          <p>
                            Freebie Flow may from time to time provide
                            enhancements or improvements to the
                            features/functionality of the website, which may
                            include patches, bug fixes, updates, upgrades and
                            other modifications ("Updates"). Updates may modify
                            or delete certain features and/or functionalities of
                            the website. You agree that Freebie Flow has no
                            obligation to (i) provide any Updates, or (ii)
                            continue to provide or enable any particular
                            features and/or functionalities of the website to
                            you. You further agree that all Updates will be (i)
                            deemed to constitute an integral part of the
                            website, and (ii) subject to the terms and
                            conditions of this Agreement.
                          </p>
                        </section>
                        <section>
                          <h2>15. Third-Party Services</h2>
                          <p>
                            We may display, include or make available
                            third-party content (including data, information,
                            applications and other products services) or provide
                            links to third-party websites or services ("Third-
                            Party Services"). You acknowledge and agree that
                            Freebie Flow shall not be responsible for any
                            Third-Party Services, including their accuracy,
                            completeness, timeliness, validity, copyright
                            compliance, legality, decency, quality or any other
                            aspect thereof. Freebie Flow does not assume and
                            shall not have any liability or responsibility to
                            you or any other person or entity for any
                            Third-Party Services. Third-Party Services and links
                            thereto are provided solely as a convenience to you
                            and you access and use them entirely at your own
                            risk and subject to such third parties' terms and
                            conditions.
                          </p>
                        </section>
                        <section>
                          <h2>16. Term and Termination</h2>
                          <p>
                            This Agreement shall remain in effect until
                            terminated by you or Freebie Flow. Freebie Flow may,
                            in its sole discretion, at any time and for any or
                            no reason, suspend or terminate this Agreement with
                            or without prior notice. This Agreement will
                            terminate immediately, without prior notice from
                            Freebie Flow, in the event that you fail to comply
                            with any provision of this Agreement. You may
                            terminate this agreement only with written notice to
                            support@freebieflow.com. Indemnification, waivers,
                            intellectual property, copyrights, and limits of
                            liability, and legal disputes survive any
                            termination of this Agreement. Termination of this
                            Agreement will not limit any of Freebie Flow's
                            rights or remedies at law or in equity in case of
                            breach by you (during the term of this Agreement) of
                            any of your obligations under the present Agreement.
                          </p>
                        </section>
                        <section>
                          <h2>17. Copyright Infringement Notice</h2>
                          <p>
                            If you are a copyright owner or such owner’s agent
                            and believe any material on our website constitutes
                            an infringement on your copyright, please contact us
                            setting forth the following information: (a) a
                            physical or electronic signature of the copyright
                            owner or a person authorized to act on his behalf;
                            (b) identification of the material that is claimed
                            to be infringing; (c) your contact information,
                            including your address, telephone number, and an
                            email; (d) a statement by you that you have a good
                            faith belief that use of the material is not
                            authorized by the copyright owners; and (e) the a
                            statement that the information in the notification
                            is accurate, and, under penalty of perjury you are
                            authorized to act on behalf of the owner.
                          </p>
                        </section>
                        <section>
                          <h2>18. Indemnification of Freebie Flow</h2>
                          <p>
                            You agree to defend, indemnify, and hold Freebie
                            Flow harmless, including our subsidiaries,
                            affiliates, and all of our respective officers,
                            agents, partners, and employees, from and against
                            any loss, damage, liability, claim, or demand,
                            including reasonable attorneys’ fees and expenses,
                            made by any third party due to or arising out of:
                            (1) your Contributions; (2) use of Freebie Flow
                            Service; (3) breach of this User Agreement/Terms of
                            Service; (4) any breach of your representations and
                            warranties set forth in this User Agreement/Terms of
                            Service; (5) your violation of the rights of a third
                            party, including but not limited to intellectual
                            property rights; or (6) any overt harmful act toward
                            any other user of this User Agreement/Terms of
                            Service with whom you connected via this User
                            Agreement/Terms of Service (7) any action by Amazon,
                            Amazon Seller, or affiliate against Freebie Flow for
                            your use of Freebie Flow Service. Notwithstanding
                            the foregoing, we reserve the right, at your
                            expense, to assume the exclusive defense and control
                            of any matter for which you are required to
                            indemnify us, and you agree to cooperate, at your
                            expense, with our defense of such claims. We will
                            use reasonable efforts to notify you of any such
                            claim, action, or proceeding which is subject to
                            this indemnification upon becoming aware of it.
                          </p>
                        </section>
                        <section>
                          <h2>
                            19. Disclaimer of Warranties; Limitation of
                            Liability
                          </h2>
                          <p>
                            We try to keep Freebie Flow Service safe, secure,
                            and functioning properly, but we cannot guarantee
                            the continuous operation of or access to our Freebie
                            Flow Service. Such functionality is subject to
                            delays beyond Freebie Flow’s control.
                          </p>
                          <p>
                            You agree that you are making use of Freebie Flow
                            Service at your own risk, and that Freebie Flow
                            Service are being provided to you on an "AS IS" and
                            "AS AVAILABLE" basis. Accordingly, to the extent
                            permitted by applicable law, we exclude all express
                            or implied warranties, terms and conditions
                            including, but not limited to, implied warranties of
                            merchantability, fitness for a particular purpose,
                            and non-infringement.
                          </p>
                          <p>
                            In addition, to the extent permitted by applicable
                            law, in no event will Freebie Flow (including our
                            parent, subsidiaries, and affiliates, and our and
                            their officers, directors, agents, and employees) be
                            liable to you or any third party under any claim at
                            law or in equity for any consequential damages or
                            losses (including, but not limited to, loss of
                            money, goodwill or reputation, profits, other
                            intangible losses, or any special, indirect, or
                            consequential damages), and all such damages or
                            losses are expressly excluded by this agreement
                            whether or not they were foreseeable or Freebie Flow
                            was advised of such damages or losses. Without
                            limiting the generality of the foregoing, we
                            (including our parent, subsidiaries, and affiliates,
                            and our and their officers, directors, agents, and
                            employees) are not liable, and you agree not to hold
                            us responsible, for any damages or losses
                            (including, but not limited to, loss of money,
                            goodwill or reputation, profits, other intangible
                            losses, or any special, indirect, or consequential
                            damages) resulting directly or indirectly from:
                          </p>
                          <ul>
                            <li>
                              the content you provide (directly or indirectly)
                              using Freebie Flow Service;
                            </li>
                            <li>
                              your use of or your inability to use Freebie Flow
                              Service;
                            </li>
                            <li>
                              pricing, shipping, format, or other guidance
                              provided by Freebie Flow;
                            </li>
                            <li>
                              delays or disruptions in Freebie Flow Service;
                            </li>
                            <li>delays or disruptions in Amazon services.</li>
                            <li>
                              viruses or other malicious software obtained by
                              accessing or linking to Freebie Flow Service;
                            </li>
                            <li>
                              glitches, bugs, errors, or inaccuracies of any
                              kind in Freebie Flow Service;
                            </li>
                            <li>
                              damage to your hardware device from the use of any
                              Freebie Flow Service;
                            </li>
                            <li>
                              the content, actions, or inactions of third
                              parties, including items listed using Freebie Flow
                              Service or the destruction of allegedly fake
                              items;
                            </li>
                            <li>
                              a suspension or other action taken with respect to
                              your account or breach of this User
                              Agreement/Terms of Service;
                            </li>
                            <li>
                              your need to modify practices, content, or
                              behavior, or your loss of or inability to do
                              business, as a result of changes to this User
                              Agreement/Terms of Service or our policies.
                            </li>
                            <li>
                              Some jurisdictions do not allow the disclaimer of
                              warranties or exclusion of damages, so such
                              disclaimers and exclusions may not apply to you.
                            </li>
                          </ul>
                          <p>
                            Regardless of the previous paragraphs, if we are
                            found to be liable, our liability to you or to any
                            third party is limited to the greater amount of fees
                            in dispute not to exceed the total fees, which you
                            paid to us in the 12 months prior to the action
                            giving rise to the liability, or $100.
                          </p>
                        </section>
                        <section>
                          <h2>
                            20. Waiver of Consumer Rights and Texas Deceptive
                            Trade Practice Act Claims
                          </h2>
                          <p>
                            You Agree that you using Freebie Flow Service you
                            waive your rights under the Deceptive Trade
                            Practices-Consumer Protection Act, Section 17.41 et
                            seq., Business & Commerce Code, a law that gives
                            consumers special rights and protections. You also
                            represent that you had consultation with an attorney
                            of my own selection and that after such consultation
                            you voluntarily consented to this waiver.
                          </p>
                        </section>
                        <section>
                          <h2>21. Release</h2>
                          <p>
                            If you have a dispute with Amazon or one or more
                            Amazon Resellers or other third party, you release
                            Freebie Flow (and our affiliates and subsidiaries,
                            and our and their respective officers, directors,
                            employees, and agents) from claims, demands, and
                            damages (actual and consequential) of every kind and
                            nature, known and unknown, arising out of or in any
                            way connected with such disputes. In entering into
                            this release, you expressly waive any protections
                            (whether statutory or otherwise) that would
                            otherwise limit the coverage of this release to
                            include only those claims which you may know or
                            suspect to exist in your favor at the time of
                            agreeing to this release.
                          </p>
                        </section>
                        <section>
                          <h2>22. Severability</h2>
                          <p>
                            If any provision of this User Agreement/Terms of
                            Service is held to be unenforceable or invalid, such
                            provision will be changed and interpreted to
                            accomplish the objectives of such provision to the
                            greatest extent possible under applicable law and
                            the remaining provisions will continue in full force
                            and effect. This User Agreement/Terms of Service,
                            together with the Privacy Policy and any other legal
                            notices published by Freebie Flow, shall constitute
                            the entire User Agreement/Terms of Service between
                            you and Freebie Flow concerning Freebie Flow
                            Service. If any provision of this User
                            Agreement/Terms of Service is deemed invalid by a
                            court of competent jurisdiction, the invalidity of
                            such provision shall not affect the validity of the
                            remaining provisions of this User Agreement/Terms of
                            Service, which shall remain in full force and
                            effect. No waiver of any term of this User
                            Agreement/Terms of Service shall be deemed a further
                            or continuing waiver of such term or any other term,
                            and Freebie Flow’s failure to assert any right or
                            provision under this User Agreement/Terms of Service
                            shall not constitute a waiver of such right or
                            provision. YOU AND FREEBIE FLOW AGREE THAT ANY CAUSE
                            OF ACTION ARISING OUT OF OR RELATED TO FREEBIE FLOW
                            SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
                            CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
                            ACTION IS PERMANENTLY BARRED
                          </p>
                        </section>
                        <section>
                          <h2> 23. Waiver</h2>
                          <p>
                            Except as provided herein, the failure to exercise a
                            right or to require performance of an obligation
                            under this User Agreement/Terms of Service shall not
                            effect a party's ability to exercise such right or
                            require such performance at any time thereafter nor
                            shall be the waiver of a breach constitute waiver of
                            any subsequent breach. Failure to exercise, and no
                            delay in exercising, on the part of either party,
                            any right or any power under this User
                            Agreement/Terms of Service shall operate as a waiver
                            of that right or power. Nor shall any single or
                            partial exercise of any right or power under this
                            User Agreement/Terms of Service preclude further
                            exercise of that or any other right granted herein.
                            In the event of a conflict between this User
                            Agreement/Terms of Service and any applicable
                            purchase or other terms, the terms of this User
                            Agreement/Terms of Service shall govern.
                          </p>
                        </section>
                        <section>
                          <h2>24. Amendments to this Agreement</h2>
                          <p>
                            Freebie Flow reserves the right, at its sole
                            discretion, to modify or replace this User
                            Agreement/Terms of Service at any time. If a
                            revision is material we will provide at least 30
                            days' notice prior to any new terms taking effect.
                            What constitutes a material change will be
                            determined at our sole discretion. By continuing to
                            access or use our website after any revisions become
                            effective, you agree to be bound by the revised
                            terms. If you do not agree to the new terms, you are
                            no longer authorized to use Freebie Flow. Oral
                            Modifications are not permitted.
                          </p>
                        </section>
                        <section>
                          <h2>25. Entire Agreement</h2>
                          <p>
                            The User Agreement/Terms of Service constitutes the
                            entire agreement between you and Freebie Flow
                            regarding your use of the Website and Freebie Flow
                            Service and supersedes all prior and contemporaneous
                            written or oral agreements between you and Freebie
                            Flow. You may be subject to additional terms and
                            conditions that apply when you use or purchase other
                            Freebie Flow's services, which Freebie Flow will
                            provide to you at the time of such use or purchase.
                          </p>
                        </section>
                        <section>
                          <h2>26. Intellectual Property</h2>
                          <p>
                            The website and its entire contents, features and
                            functionality (including but not limited to all
                            information, software, text, displays, images, video
                            and audio, and the design, selection and arrangement
                            thereof), are owned by Freebie Flow, its licensors
                            or other providers of such material and are
                            protected by USA and international copyright,
                            trademark, patent, trade secret and other
                            intellectual property or proprietary rights laws.
                            The material may not be copied, modified,
                            reproduced, downloaded or distributed in any way, in
                            whole or in part, without the express prior written
                            permission of Freebie Flow, unless and except as is
                            expressly provided in this User Agreement/Terms of
                            Service. Any unauthorized use of the material is
                            prohibited.
                          </p>
                        </section>
                        <section>
                          <h2>27. Legal Disputes and Agreement to Arbitrate</h2>
                          <p className="font-bold">
                            PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR
                            RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON HOW
                            CLAIMS YOU AND FREEBIE FLOW HAVE AGAINST EACH OTHER
                            ARE RESOLVED.
                          </p>
                          <p>
                            In this Legal Disputes section, the term "related
                            third parties" includes your and Freebie Flow’s
                            respective affiliates, subsidiaries, parent
                            companies, predecessors, successors, assigns, as
                            well as your, Freebie Flow, and these entities'
                            respective employees and agents. You and Freebie
                            Flow agree that any claim or dispute at law or
                            equity that has arisen, or may arise, between you
                            and Freebie Flow (or any related third parties) that
                            relates in any way to or arises out of this or
                            previous versions of this User Agreement/Terms of
                            Service, your use of or access to Freebie Flow
                            Service, the actions of Freebie Flow or its agents,
                            or any products or services sold or purchased
                            through the Services, will be resolved in accordance
                            with the provisions set forth in this Legal Disputes
                            section.
                          </p>
                          <h2>27.1 Applicable Law</h2>
                          <p>
                            You agree that, except to the extent inconsistent
                            with or preempted by federal law, the laws of the
                            State of Texas, without regard to principles of
                            conflict of laws, will govern this User
                            Agreement/Terms of Service and any claim or dispute
                            that has arisen or may arise between you and Freebie
                            Flow, except as otherwise stated in this User
                            Agreement/Terms of Service.
                          </p>
                          <h2>27.2 Informal Negotiation</h2>
                          <p>
                            You agree that prior to commencing any legal
                            proceeding or arbitration you will contact Freebie
                            Flow at support@freebieflow.com to attempt to
                            resolve any issue. If you cannot resolve your issue
                            through contact with support, then Freebie Flow and
                            You will agree to do an informal zoom or other
                            remote conference software negotiation to try to
                            resolve the issue. If that cannot be done then the
                            parties will move the issues dispute resolution
                            process below.
                          </p>
                          <h2>27.3 Binding Arbitration</h2>
                          <p>
                            If the Parties are unable to resolve any dispute,
                            claim, action, cause of action, issue, or request
                            for relief between you and Company arising out of or
                            relating to Company, the Site, or this User
                            Agreement/Terms of Service (collectively “Disputes”)
                            through informal good faith negotiations, which
                            shall be a precondition to either party initiating
                            arbitration, the Dispute (except those Disputes
                            expressly excluded below) will be finally and
                            exclusively resolved by binding arbitration. YOU
                            UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
                            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY
                            TRIAL. YOU AND COMPANY WAIVE ANY CONSTITUTIONAL AND
                            STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
                            FRONT OF A JUDGE OR JURY. You and COMPANY are
                            instead choosing to have claims and disputes
                            resolved by arbitration. Arbitration procedures are
                            typically more limited, more efficient, and less
                            costly than rules applicable in court and are
                            subject to very limited review by a court. In any
                            litigation between you and Company over whether to
                            vacate or enforce an arbitration award, YOU AND
                            COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
                            instead to have the dispute be resolved by a judge.
                            The arbitration shall be commenced and conducted
                            under the Commercial Arbitration Rules of the
                            American Arbitration Association ("AAA") and, where
                            appropriate, the AAA’s Supplementary Procedures for
                            Consumer Related Disputes ("AAA Consumer Rules"),
                            both of which are available at the AAA website:
                            www.adr.org. Your arbitration fees and your share of
                            arbitrator compensation shall be governed by the AAA
                            Consumer Rules and, where appropriate, limited by
                            the AAA Consumer Rules. The arbitration may be
                            conducted in person, through the submission of
                            documents, by phone, or online. The arbitrator will
                            make a decision in writing, but need not provide a
                            statement of reasons unless requested by either
                            Party. The arbitrator must follow applicable law,
                            and any award may be challenged if the arbitrator
                            fails to do so. Except where otherwise required by
                            the applicable AAA rules or applicable law, the
                            arbitration will take place in Fort Bend County,
                            Texas. Except as otherwise provided herein, the
                            Parties may litigate in court to compel arbitration,
                            stay proceedings pending arbitration, or to confirm,
                            modify, vacate, or enter judgment on the award
                            entered by the arbitrator. If for any reason, a
                            Dispute proceeds in court rather than arbitration,
                            the Dispute shall be commenced or prosecuted in the
                            state court of Fort Bend County Texas or federal
                            courts located in Harris County, Texas, and the
                            Parties hereby consent to, and waive all defenses of
                            lack of personal jurisdiction, and forum non
                            conveniens with respect to venue and jurisdiction in
                            such state and federal courts. Application of the
                            United Nations Convention on Contracts for the
                            International Sale of Goods and the Uniform Computer
                            Information Transaction Act (UCITA) are excluded
                            from this User Agreement/Terms of Service. If this
                            provision is found to be illegal or unenforceable,
                            then neither Party will elect to arbitrate any
                            Dispute falling within that portion of this
                            provision found to be illegal or unenforceable and
                            such Dispute shall be decided by a court of
                            competent jurisdiction within the courts listed for
                            jurisdiction above, and the Parties agree to submit
                            to the personal jurisdiction of that court.
                          </p>
                          <h2>27.4 Restrictions</h2>
                          <p>
                            The Parties agree that any arbitration shall be
                            limited to the Dispute between the Parties
                            individually. To the full extent permitted by law,
                            (a) no arbitration shall be joined with any other
                            proceeding; (b) there is no right or authority for
                            any Dispute to be arbitrated on a class-action basis
                            or to utilize class action procedures; and (c) there
                            is no right or authority for any Dispute to be
                            brought in a purported representative capacity on
                            behalf of the general public or any other persons.
                          </p>
                          <h2>27.5 Exceptions to Arbitration</h2>
                          <p>
                            The Parties agree that the following Disputes are
                            not subject to the above provisions concerning
                            binding arbitration: (a) any Disputes seeking to
                            enforce or protect, or concerning the validity of,
                            any of the intellectual property rights of a Party;
                            (b) any Dispute related to, or arising from,
                            allegations of theft, piracy, invasion of privacy,
                            or unauthorized use; and (c) any claim for
                            injunctive relief. If this provision is found to be
                            illegal or unenforceable, then neither Party will
                            elect to arbitrate any Dispute falling within that
                            portion of this provision found to be illegal or
                            unenforceable and such Dispute shall be decided by a
                            court of competent jurisdiction within the courts
                            listed for jurisdiction above, and the Parties agree
                            to submit to the personal jurisdiction of that
                            court.
                          </p>
                          <h2>27.6 Opt-Out</h2>
                          <p>
                            You can decline this agreement to arbitrate by
                            emailing us at support@freebieflow.com and providing
                            the requested information as follows: (1) your name;
                            (2) your Address; (3) your username; (4) your phone
                            number; (5) and a clear statement that you wish to
                            opt out of this arbitration provision in the User
                            Agreement/Terms of Service. The Opt-Out notice must
                            be emailed no later than 30 days after the date you
                            first accept the User Agreement/Terms of Service by
                            using the Freebie Flow Service.
                          </p>
                        </section>
                        <section>
                          <h2>28. Submissions and Privacy</h2>
                          <p>
                            In the event that you submit or post any ideas,
                            creative suggestions, designs, photographs,
                            information, advertisements, data or proposals,
                            including ideas for new or improved products,
                            services, features, technologies or promotions, you
                            expressly agree that such submissions will
                            automatically be treated as non-confidential and
                            non- proprietary and will become the sole property
                            of Freebie Flow without any compensation or credit
                            to you whatsoever. Freebie Flow and its affiliates
                            shall have no obligations with respect to such
                            submissions or posts and may use the ideas contained
                            in such submissions or posts for any purposes in any
                            medium in perpetuity, including, but not limited to,
                            developing, manufacturing, and marketing products
                            and services using such ideas.
                          </p>
                        </section>
                        <section>
                          <h2>29. Promotions</h2>
                          <p>
                            Freebie Flow may, from time to time, include
                            contests, promotions, sweepstakes, or other
                            activities (“Promotions”) that require you to submit
                            material or information concerning yourself. Please
                            note that all Promotions may be governed by separate
                            rules that may contain certain eligibility
                            requirements, such as restrictions as to age and
                            geographic location. You are responsible to read all
                            Promotions rules to determine whether or not you are
                            eligible to participate. If you enter any Promotion,
                            you agree to abide by and to comply with all
                            Promotions Rules. Additional terms and conditions
                            may apply to purchases of goods or services on or
                            through Freebie Flow Service, which terms and
                            conditions are made a part of this Agreement by this
                            reference.
                          </p>
                        </section>
                        <section>
                          <h2>30. Typographical Errors</h2>
                          <p>
                            In the event a product and/or service is listed at
                            an incorrect price or with incorrect information due
                            to typographical error, we shall have the right to
                            refuse or cancel any orders placed for the product
                            and/or service listed at the incorrect price. We
                            shall have the right to refuse or cancel any such
                            order whether or not the order has been confirmed
                            and your credit card charged. If your credit card
                            has already been charged for the purchase and your
                            order is canceled, we shall immediately issue a
                            credit to your credit card account or other payment
                            account in the amount of the charge.
                          </p>
                        </section>
                        <section>
                          <h2>Miscellaneous Clauses</h2>
                          <p>
                            If you create or use an account on behalf of a
                            business entity, you represent that you are
                            authorized to act on behalf of such business and
                            bind the business to this User Agreement/Terms of
                            Service. Such account is owned and controlled by the
                            business entity. No agency, partnership, joint
                            venture, employee-employer or franchiser-franchisee
                            relationship is intended or created by this User
                            Agreement/Terms of Service. If you are a California
                            resident, in accordance with Cal. Civ. Code §1789.3,
                            you may report complaints to the Complaint
                            Assistance Unit of the Division of Consumer Services
                            of the California Department of Consumer Affairs by
                            contacting them in writing at 400 R Street,
                            Sacramento, CA 95814, or by telephone at (800)
                            952-5210. Headings are for reference purposes only
                            and do not limit the scope or extent of such
                            section. Our failure to act with respect to a breach
                            by you or others does not waive our right to act
                            with respect to subsequent or similar breaches. We
                            do not guarantee we will take action against all
                            breaches of this User Agreement/Terms of Service. If
                            you have any questions or concerns about these User
                            Agreement/Terms of Service please contact us at
                            support@freebieflow.com
                          </p>
                        </section>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default TermsOfServiceModal;
