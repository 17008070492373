import axios from 'axios';
import toast from 'react-hot-toast';
import { churnkeyMode } from 'config';

export const displayChurnkeyWindow = (user, connectToSupport) => {
  return axios('/churnkey/cancel', {
    method: 'GET'
  })
    .then(async ({ data }) => {
      let hash = data.hash;
      delete data.hash;
      window.churnkey.init('show', {
        subscriptionId: user.subscription.id, // recommended unless Paddle
        customerId: user.subscription.customer, // required unless Paddle
        authHash: hash, // required
        appId: '2w7hbd3pr', // required
        mode: churnkeyMode, // set to 'test' to hit test billing provider environment
        provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
        record: true, // set to false to skip session playback recording
        handleSupportRequest: customer => {
          connectToSupport(); 
        },
        customerAttributes: {
          hadActiveAccount: data.hadActiveAccount,
          language: data.language,
          moneySpent: data.moneySpent.toLocaleString(),
          savingsMultiplier: data.savingsMultiplier.toLocaleString(),
          freebieCheckouts: data.freebieCheckouts.toLocaleString(),
          freebieValue: data.freebieValue.toLocaleString(),
          pendingFreebies: data.pendingFreebies.toLocaleString(),
          pendingFreebieValue: data.pendingFreebieValue.toLocaleString(),
          dealCheckouts: data.dealCheckouts.toLocaleString(),
          dealValue: data.dealValue.toLocaleString(),
          pendingDeals: data.pendingDeals.toLocaleString(),
          pendingDealValue: data.pendingDealValue.toLocaleString(),
          mostExpensiveItem: data.mostExpensiveItem,
        },
        i18n: {
          lang: user.language || 'en'
        }
      });
    })
    .catch(e => {
      const error = e?.response?.data?.error;
      toast.error(error ?? 'Something went wrong while cancelling!');
    });
};
