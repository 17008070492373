/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import toast from 'react-hot-toast';
import {
  FaChevronLeft,
  FaChevronRight,
  FaEye,
  FaEyeSlash,
  FaInfoCircle,
  FaStar
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import logo from 'assets/logo.png';
import reviewData from 'context/reviewData';
import TermsOfServiceModal from 'dashboard/components/TermsOfServiceModal';

export default function Register() {
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showTermsOfServiceModal, setShowTermsOfServiceModal] = useState(false);

  const [valid, setValid] = useState(false);
  const [slideNum, setSlideNum] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState('');

  const languagedetector = new LanguageDetector();

  let language = languagedetector.detect();

  if (language !== 'es' && !language.includes('en-US')) {
    language = 'en';
  }

  const { t } = useTranslation('', {
    lng: language
  });

  const validateEmail = emailToValidate => {
    const re = /\S+@\S+\.\S+/;
    return re.test(emailToValidate);
  };

  const validatePassword = passwordToValidate => {
    const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return re.test(passwordToValidate);
  };

  const validateConfirmPassword = (passwordInput, passwordToConfirm) => {
    return passwordInput === passwordToConfirm;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validate = () => {
    if (
      validateEmail(email) &&
      validatePassword(password) &&
      validateConfirmPassword(password, confirmPassword)
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    try {
      let refurl = 'none';
      if (window?.frames?.top?.document?.referrer) {
        refurl = window.frames.top.document.referrer;
      }
      window.posthog.capture('user_visited_register_page', {
        referrer: refurl
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    validate();
  }, [email, password, confirmPassword]);

  const postData = async e => {
    e.preventDefault();
    setErrorMessage('');

    if (valid) {
      axios('/register', {
        method: 'POST',
        data: {
          firstName,
          lastName,
          username: email,
          password
        }
      })
        .then(response => {
          const data = response.data;

          try {
            window.posthog.identify(data._id.toString(), {
              email: email,
              'First Name': firstName,
              'Last Name': lastName
            });
          } catch (e) {
            console.log(e);
          }

          toast.success('Registered successfully, please confirm your email');
          window.location.pathname = '/dashboard';
        })
        .catch(e2 => {
          const error = e2?.response?.data?.error;
          toast.error(error ?? 'Something went wrong while registering!');
          setErrorMessage(error ?? 'Something went wrong while registering!');
        });
    } else if (!validateConfirmPassword(password, confirmPassword)) {
      toast.error('Passwords do not match.');
      setErrorMessage('Passwords do not match.');
    }
  };

  return (
    <div>
      <section className="flex flex-col lg:flex-row-reverse">
        <div className="flex flex-col justify-center w-full px-5 py-12 overflow-clip bg-background sm:px-6 lg:w-1/2 lg:justify-end lg:px-8">
          <div className="relative sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img
                className="w-auto h-10 mx-auto"
                src={logo}
                alt="FreebieFlow"
              />
            </Link>
            <h2 className="mt-6 text-3xl font-semibold tracking-tight text-center text-gray-900">
              {t('register.regAccount')}
            </h2>
            <p className="mb-4 text-sm text-center text-gray-700">
              {t('register.regBody')}
            </p>
            <p className="mt-2 text-sm text-center text-gray-800">
              {t('home.or')}{' '}
              <Link
                to="/dashboard/signin"
                className="font-medium text-gradient"
              >
                {t('navBar.signIn')}
              </Link>
            </p>
          </div>
          <div className="relative mt-8 overflow-hidden rounded-md sm:mx-auto sm:w-full sm:max-w-md">
            <div className="w-full h-1 bg-gradient rounded-t-md" />
            <div className="px-4 py-8 bg-white border-b rounded-b-md border-x sm:px-10">
              <form onSubmit={postData} className="space-y-6">
                <div className="flex gap-2.5">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-800"
                    >
                      {t('register.firstName')}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={e => {
                          setFirstName(e.target.value);
                        }}
                        id="firstName"
                        name="firstName"
                        type="firstName"
                        autoComplete="First Name"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-800"
                    >
                      {t('register.lastName')}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={e => {
                          setLastName(e.target.value);
                        }}
                        id="lastName"
                        name="lastName"
                        type="lastName"
                        autoComplete="Last name"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-800"
                  >
                    {t('register.emailAddress')}
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="flex items-center justify-between text-sm font-medium text-gray-800"
                  >
                    {t('register.password')}
                  </label>
                  <div>
                    <div className="relative mt-1">
                      <input
                        onChange={e => {
                          setPassword(e.target.value);
                          validate();
                        }}
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        required
                        className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 px-3 py-1"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <FaEyeSlash className="text-gray-400" />
                        ) : (
                          <FaEye className="text-gray-400" />
                        )}
                      </button>
                    </div>
                  </div>
                  {password.length > 2 ? (
                    <span className="text-xs font-normal text-rose-500">
                      {password.length < 8 &&
                        'Password must be at least 8 characters long.'}
                      {password.length >= 8 &&
                        !validatePassword(password) &&
                        'Use at least one uppercase letter and one number.'}
                    </span>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-800"
                  >
                    {t('register.confirmPassword')}
                  </label>
                  <div className="relative mt-1">
                    <input
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                      }}
                      id="confirm-password"
                      name="confirm-password"
                      type={showPassword ? 'text' : 'password'}
                      required
                      className="block w-full px-3 py-2 placeholder-gray-400 transition border border-gray-300 rounded-md shadow-sm appearance-none focus:border-highlight focus:outline-none focus:ring-highlight sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center mt-6">
                    <input
                      id="terms"
                      name="terms"
                      type="checkbox"
                      required
                      className="w-4 h-4 border-gray-300 rounded text-highlight focus:ring-highlight"
                    />
                    <label
                      htmlFor="terms"
                      className="block ml-2 text-sm text-gray-800"
                    >
                      {t('register.iAgree')}{' '}
                      <button
                        onClick={() => setShowTermsOfServiceModal(true)}
                        className="font-medium text-gradient"
                        type="button"
                      >
                        {t('register.terms')}
                      </button>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mt-2 mt-6">
                    <input
                      id="acknowledgeusa"
                      name="acknowledgeusa"
                      type="checkbox"
                      required
                      className="w-4 h-4 border-gray-300 rounded text-highlight focus:ring-highlight"
                    />
                    <label
                      htmlFor="acknowledgeusa"
                      className="block ml-2 text-sm text-gray-800"
                    >
                      {t('register.acknowledgeLang')}
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex justify-center w-full button-gradient"
                  >
                    {t('register.reg')}
                  </button>
                  {errorMessage && (
                    <div className="mt-2 text-red-500">{errorMessage}</div>
                  )}
                </div>
              </form>
            </div>

            <div className="px-4 py-8 mt-8 bg-white border rounded-md sm:px-10">
              <h3 className="mb-8 flex items-center gap-1.5 font-semibold">
                <FaInfoCircle />
                {t('register.info')}
              </h3>

              <h4 className="font-medium">{t('register.linkAcctNow')}</h4>
              <p className="mt-2 text-sm text-gray-500">
                {t('register.linkBody')}
              </p>

              <h4 className="mt-8 font-medium">{t('register.payReq')}</h4>
              <p className="mt-2 text-sm text-gray-500">
                {t('register.payReqBody')}
              </p>
            </div>
          </div>
        </div>
        <div className="left-0 w-full lg:fixed lg:h-screen lg:w-1/2">
          <div className="relative h-[50vh] w-full overflow-hidden bg-cover bg-center transition-all duration-500 ease-in-out lg:h-screen">
            <div className="absolute z-10 w-full h-full bg-gradient-to-t from-transparent via-black/60 to-black lg:bg-gradient-to-b" />
            <img
              src={reviewData[slideNum].image}
              className="object-cover w-full h-full"
              alt="slide"
            />
            <div className="absolute bottom-[40%] z-[999] px-5 lg:px-10">
              <div className="mb-5 flex justify-center gap-1.5 text-white lg:justify-start lg:text-xl">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
              </div>
              <h1 className="text-xl font-medium text-center text-white lg:text-left lg:text-2xl">
                {reviewData[slideNum].body}
              </h1>
              <p className="mt-2 text-center text-white lg:text-left">
                {reviewData[slideNum].name}
              </p>
            </div>
            <div className="absolute z-50 inline-flex justify-between w-full px-5 pb-40 transform -translate-x-1/2 -translate-y-1/2 left-1/2 lg:top-2/3 lg:px-10 lg:pb-0 ">
              <button
                onClick={() => {
                  if (slideNum === 0) {
                    setSlideNum(10);
                  } else {
                    setSlideNum(slideNum - 1);
                  }
                }}
                type="button"
                aria-label="Go back"
                className="p-5 text-4xl text-white transition rounded-full bg-black/80 hover:bg-black/20 active:bg-black/30"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={() => {
                  if (slideNum === reviewData.length - 1) {
                    setSlideNum(0);
                  } else {
                    setSlideNum(slideNum + 1);
                  }
                }}
                type="button"
                aria-label="Go back"
                className="p-5 text-4xl text-white transition rounded-full bg-black/80 hover:bg-black/20 active:bg-black/30"
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </section>
      <TermsOfServiceModal
        showTermsOfServiceModal={showTermsOfServiceModal}
        setShowTermsOfServiceModal={setShowTermsOfServiceModal}
      />
    </div>
  );
}
